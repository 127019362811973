// @flow

import { getNumDaysSinceEpoch } from './utils.js';

// [startWord, endWord, optimalPathLength, optimalPath, specialEmoji, specialMessage, specialMessageBackgroundColor]
export const wordPairs = [
  ['rain', 'snow', 6, ['rain', 'cain', 'chin', 'chon', 'chow', 'show', 'snow']],
  ['type', 'word', 4, ['type', 'tope', 'tore', 'wore', 'word']],
  ['open', 'shut', 6, ['open', 'oped', 'ohed', 'shed', 'shad', 'shat', 'shut']],
  ['ball', 'cube', 5, ['ball', 'bale', 'bake', 'cake', 'cuke', 'cube']],
  ['rock', 'dust', 4, ['rock', 'dock', 'duck', 'duct', 'dust']],
  ['poor', 'rich', 6, ['poor', 'boor', 'book', 'bock', 'rock', 'rick', 'rich']],
  ['monk', 'pray', 6, ['monk', 'mons', 'moas', 'boas', 'bras', 'bray', 'pray']],
  ['beer', 'wine', 5, ['beer', 'bees', 'bens', 'bene', 'bine', 'wine']],
  ['hide', 'seek', 6, ['hide', 'bide', 'bids', 'beds', 'bees', 'sees', 'seek']],
  ['tree', 'bark', 5, ['tree', 'tyee', 'tyre', 'byre', 'bare', 'bark']],
  ['taco', 'meat', 6, ['taco', 'tace', 'mace', 'male', 'malt', 'melt', 'meat']],
  ['slow', 'poke', 6, ['slow', 'plow', 'plod', 'pood', 'pond', 'pone', 'poke']],
  ['door', 'jamb', 6, ['door', 'doer', 'does', 'doms', 'dams', 'jams', 'jamb']],
  ['text', 'call', 5, ['text', 'teat', 'teal', 'tell', 'cell', 'call']],
  ['mind', 'soul', 6, ['mind', 'bind', 'bins', 'sins', 'sons', 'sous', 'soul']],
  ['lose', 'find', 4, ['lose', 'lone', 'line', 'fine', 'find']],
  ['gala', 'fete', 4, ['gala', 'gale', 'gate', 'fate', 'fete']],
  ['flat', 'tire', 6, ['flat', 'blat', 'boat', 'bort', 'bore', 'tore', 'tire']],
  ['pork', 'chop', 4, ['pork', 'cork', 'cook', 'coop', 'chop']],
  ['pink', 'blue', 7, ['pink', 'fink', 'fins', 'fens', 'feus', 'flus', 'flue', 'blue']],
  ['jail', 'free', 6, ['jail', 'bail', 'bait', 'brit', 'brie', 'bree', 'free']],
  ['frog', 'toad', 4, ['frog', 'trog', 'trod', 'trad', 'toad']],
  ['goof', 'ball', 5, ['goof', 'golf', 'gold', 'bold', 'bald', 'ball']],
  ['cole', 'slaw', 6, ['cole', 'bole', 'bolt', 'boat', 'blat', 'blaw', 'slaw']],
  ['dish', 'bowl', 5, ['dish', 'diss', 'doss', 'boss', 'bows', 'bowl']],
  ['bath', 'soap', 6, ['bath', 'bach', 'back', 'bock', 'sock', 'soak', 'soap']],
  ['wild', 'tame', 4, ['wild', 'wile', 'tile', 'tale', 'tame']],
  ['life', 'boat', 5, ['life', 'lift', 'loft', 'coft', 'coat', 'boat']],
  ['good', 'luck', 5, ['good', 'goof', 'loof', 'look', 'lock', 'luck'], '🍀', "Saint Patrick's Day", 'green'], // 3/17/2022 -- Saint Patrick's Day
  ['papa', 'bear', 5, ['papa', 'paps', 'peps', 'peas', 'pear', 'bear']],
  ['thin', 'mint', 6, ['thin', 'than', 'that', 'teat', 'tent', 'tint', 'mint']],
  ['what', 'ever', 7, ['what', 'that', 'thae', 'thee', 'tyee', 'tyer', 'eyer', 'ever']],
  ['slow', 'down', 5, ['slow', 'slot', 'soot', 'soon', 'sown', 'down']],
  ['hush', 'yell', 5, ['hush', 'husk', 'hulk', 'hull', 'hell', 'yell']],
  ['rock', 'sand', 4, ['rock', 'rack', 'rank', 'rand', 'sand']],
  ['fish', 'hook', 5, ['fish', 'fist', 'hist', 'host', 'hoot', 'hook']],
  ['solo', 'team', 5, ['solo', 'sols', 'sels', 'seas', 'seam', 'team']],
  ['town', 'city', 6, ['town', 'torn', 'corn', 'core', 'cire', 'cite', 'city']],
  ['atom', 'cell', 7, ['atom', 'atop', 'stop', 'step', 'seep', 'seel', 'sell', 'cell']],
  ['punt', 'kick', 4, ['punt', 'pint', 'pink', 'kink', 'kick']],
  ['camp', 'fire', 4, ['camp', 'came', 'care', 'cire', 'fire']],
  ['lies', 'fact', 5, ['lies', 'pies', 'pics', 'pacs', 'pact', 'fact']],
  ['peat', 'moss', 4, ['peat', 'meat', 'moat', 'moas', 'moss']],
  ['fool', 'joke', 5, ['fool', 'fowl', 'howl', 'howe', 'hoke', 'joke'], '🥸', "April Fool's Day", 'purple'], // 4/1/2022 -- April Fool's Day
  ['free', 'love', 6, ['free', 'tree', 'tyee', 'tyne', 'tone', 'lone', 'love']],
  ['home', 'brew', 7, ['home', 'come', 'cole', 'cold', 'coed', 'cred', 'bred', 'brew']],
  ['soft', 'firm', 4, ['soft', 'sort', 'fort', 'form', 'firm']],
  ['head', 'foot', 5, ['head', 'bead', 'beat', 'boat', 'boot', 'foot']],
  ['full', 'moon', 4, ['full', 'mull', 'moll', 'mool', 'moon']],
  ['slip', 'fall', 5, ['slip', 'flip', 'flir', 'fair', 'fail', 'fall']],
  ['home', 'sick', 5, ['home', 'dome', 'dime', 'dice', 'dick', 'sick']],
  ['palm', 'tree', 6, ['palm', 'pale', 'pare', 'pyre', 'tyre', 'tyee', 'tree']],
  ['over', 'come', 7, ['over', 'oyer', 'dyer', 'doer', 'does', 'doms', 'dome', 'come']],
  ['cows', 'milk', 4, ['cows', 'cols', 'mols', 'mils', 'milk']],
  ['neck', 'lace', 4, ['neck', 'beck', 'back', 'lack', 'lace']],
  ['news', 'cast', 5, ['news', 'mews', 'maws', 'mass', 'mast', 'cast']],
  ['page', 'book', 5, ['page', 'pace', 'pack', 'back', 'bock', 'book']],
  ['pray', 'amen', 7, ['pray', 'gray', 'grad', 'grid', 'arid', 'amid', 'amin', 'amen'], '🙏', "Good Friday and Passover (first day)", 'blue'], // 4/15/2022 -- Good Friday and Passover (first day)
  ['pawn', 'king', 5, ['pawn', 'paws', 'pans', 'pang', 'ping', 'king']],
  ['hide', 'eggs', 6, ['hide', 'aide', 'aids', 'ands', 'ends', 'engs', 'eggs'], '🐰', "Easter", 'pink'], // Sunday, 4/17/2022 -- Easter
  ['less', 'more', 4, ['less', 'loss', 'lose', 'lore', 'more']],
  ['soul', 'mate', 5, ['soul', 'saul', 'maul', 'mall', 'male', 'mate']],
  ['moon', 'star', 5, ['moon', 'boon', 'boor', 'boar', 'soar', 'star']],
  ['tiny', 'huge', 5, ['tiny', 'liny', 'line', 'lune', 'luge', 'huge']],
  ['root', 'tree', 4, ['root', 'toot', 'trot', 'tret', 'tree'], '🌎', "Earth Day", 'blue'], // 4/22/2022 -- Earth Day
  ['four', 'wine', 6, ['four', 'dour', 'dorr', 'dore', 'dire', 'dine', 'wine'], '🍷', "Passover (last day)", 'blue'], // 4/23/2022 -- Passover (last day)
  ['long', 'shot', 6, ['long', 'bong', 'bonk', 'book', 'boot', 'soot', 'shot']],
  // Monday
  ['hold', 'fast', 4, ['hold', 'holt', 'halt', 'hast', 'fast']],
  ['time', 'warp', 4, ['time', 'tame', 'tamp', 'tarp', 'warp']],
  ['tree', 'fort', 5, ['tree', 'tres', 'toes', 'tors', 'tort', 'fort']],
  ['bird', 'song', 4, ['bird', 'bind', 'bond', 'bong', 'song']],
  ['left', 'turn', 5, ['left', 'loft', 'toft', 'tort', 'torn', 'turn']],
  ['sink', 'swim', 6, ['sink', 'sank', 'sand', 'said', 'skid', 'skim', 'swim']],
  ['game', 'over', 7, ['game', 'gams', 'gaes', 'kaes', 'kyes', 'oyes', 'oyer', 'over']],
  // Monday
  ['lost', 'soul', 4, ['lost', 'lout', 'loup', 'soup', 'soul']],
  ['mind', 'game', 4, ['mind', 'mine', 'mane', 'gane', 'game']],
  ['shoe', 'sock', 4, ['shoe', 'shot', 'soot', 'sook', 'sock']],
  ['long', 'haul', 5, ['long', 'hong', 'hang', 'hant', 'haut', 'haul']],
  ['move', 'fast', 5, ['move', 'cove', 'cave', 'case', 'cast', 'fast']],
  ['wolf', 'pack', 6, ['wolf', 'rolf', 'role', 'pole', 'pale', 'pace', 'pack']],
  ['four', 'five', 6, ['four', 'dour', 'dorr', 'dore', 'dire', 'dive', 'five']],
  // Monday
  ['bank', 'roll', 4, ['bank', 'balk', 'ball', 'boll', 'roll']],
  ['junk', 'food', 4, ['junk', 'funk', 'fund', 'fond', 'food']],
  ['nice', 'easy', 5, ['nice', 'bice', 'bise', 'base', 'ease', 'easy']],
  ['lock', 'keys', 5, ['lock', 'lack', 'lacs', 'lays', 'kays', 'keys']],
  ['film', 'noir', 6, ['film', 'fill', 'bill', 'boll', 'boil', 'noil', 'noir']],
  ['talk', 'chat', 6, ['talk', 'calk', 'cask', 'cast', 'cost', 'coat', 'chat']],
  ['milk', 'eggs', 7, ['milk', 'mils', 'ails', 'aids', 'ands', 'ends', 'engs', 'eggs']],
  // Monday
  ['rise', 'fall', 4, ['rise', 'rile', 'file', 'fill', 'fall']],
  ['give', 'take', 4, ['give', 'dive', 'dike', 'tike', 'take']],
  ['foot', 'mile', 4, ['foot', 'moot', 'molt', 'milt', 'mile']],
  ['team', 'mate', 5, ['team', 'teas', 'tets', 'tats', 'mats', 'mate']],
  ['surf', 'wave', 5, ['surf', 'curf', 'cure', 'care', 'cave', 'wave']],
  ['quiz', 'test', 6, ['quiz', 'quit', 'duit', 'doit', 'dost', 'tost', 'test']],
  ['high', 'jump', 7, ['high', 'sigh', 'sinh', 'sins', 'sims', 'simp', 'jimp', 'jump']],
  // Monday
  ['fail', 'pass', 4, ['fail', 'fall', 'pall', 'pals', 'pass']],
  ['lion', 'tame', 4, ['lion', 'limn', 'lime', 'lame', 'tame']],
  ['note', 'book', 4, ['note', 'none', 'bone', 'bonk', 'book']],
  ['fist', 'bump', 5, ['fist', 'list', 'lisp', 'limp', 'lump', 'bump']],
  ['sumo', 'ring', 5, ['sumo', 'sums', 'rums', 'rims', 'rins', 'ring']],
  ['sunk', 'ship', 6, ['sunk', 'dunk', 'dunt', 'duit', 'suit', 'shit', 'ship']],
  ['nose', 'swab', 7, ['nose', 'hose', 'host', 'hest', 'heat', 'seat', 'swat', 'swab']],
  // Monday
  ['done', 'deal', 4, ['done', 'dene', 'dele', 'dell', 'deal']],
  ['wolf', 'howl', 4, ['wolf', 'woof', 'hoof', 'howf', 'howl']],
  ['bear', 'claw', 4, ['bear', 'beam', 'blam', 'blaw', 'claw']],
  ['pump', 'fake', 5, ['pump', 'dump', 'damp', 'dame', 'fame', 'fake']],
  ['code', 'hack', 5, ['code', 'cade', 'care', 'cark', 'hark', 'hack']],
  ['gosh', 'darn', 6, ['gosh', 'bosh', 'bash', 'base', 'bare', 'barn', 'darn']],
  ['kilo', 'gram', 7, ['kilo', 'kill', 'gill', 'gild', 'gold', 'goad', 'grad', 'gram']],
  // Monday
  ['coal', 'fire', 5, ['coal', 'foal', 'foam', 'form', 'firm', 'fire']],
  ['jump', 'rope', 5, ['jump', 'pump', 'pomp', 'pome', 'pope', 'rope']],
  ['lose', 'gain', 5, ['lose', 'lase', 'last', 'gast', 'gait', 'gain']],
  ['nick', 'name', 5, ['nick', 'dick', 'dice', 'dace', 'dame', 'name']],
  ['farm', 'oink', 5, ['farm', 'fare', 'fane', 'fine', 'fink', 'oink']],
  ['door', 'knob', 6, ['door', 'boor', 'boob', 'blob', 'slob', 'snob', 'knob']],
  ['mint', 'chip', 7, ['mint', 'dint', 'dunt', 'duit', 'suit', 'shit', 'chit', 'chip']],
  // Monday
  ['head', 'toes', 4, ['head', 'heed', 'hoed', 'hoes', 'toes']],
  ['duck', 'pond', 5, ['duck', 'buck', 'bock', 'bonk', 'bond', 'pond']],
  ['sail', 'boat', 5, ['sail', 'bail', 'baal', 'baas', 'boas', 'boat']],
  ['beta', 'test', 5, ['beta', 'bets', 'bees', 'beet', 'best', 'test']],
  ['tilt', 'spin', 6, ['tilt', 'silt', 'salt', 'sall', 'sail', 'sain', 'spin']],
  ['left', 'over', 6, ['left', 'deft', 'deet', 'deer', 'dyer', 'oyer', 'over']],
  ['acid', 'base', 7, ['acid', 'arid', 'grid', 'grit', 'brit', 'bait', 'bast', 'base']],
  // Monday
  ['long', 'time', 4, ['long', 'ling', 'line', 'lime', 'time']],
  ['solo', 'duet', 5, ['solo', 'sols', 'dols', 'does', 'dues', 'duet']],
  ['born', 'free', 6, ['born', 'bore', 'byre', 'tyre', 'tyee', 'tree', 'free']],
  ['show', 'tell', 6, ['show', 'shaw', 'sham', 'seam', 'seal', 'sell', 'tell']],
  ['slow', 'fast', 6, ['slow', 'flow', 'flaw', 'flat', 'feat', 'fest', 'fast']],
  ['skim', 'milk', 6, ['skim', 'skis', 'seis', 'sels', 'mels', 'mils', 'milk']],
  ['null', 'void', 7, ['null', 'cull', 'curl', 'curd', 'cord', 'lord', 'loid', 'void']],
  // Monday
  ['oust', 'outs', 4, ['oust', 'bust', 'buss', 'buts', 'outs']],
  ['zone', 'mark', 5, ['zone', 'bone', 'bane', 'bank', 'bark', 'mark']],
  ['rack', 'city', 5, ['rack', 'pack', 'pacy', 'paty', 'pity', 'city']],
  ['play', 'ball', 6, ['play', 'plan', 'pian', 'pial', 'pill', 'bill', 'ball']],
  ['swan', 'lake', 7, ['swan', 'span', 'spas', 'seas', 'leas', 'leks', 'leke', 'lake']],
  ['baby', 'step', 7, ['baby', 'gaby', 'gabs', 'gaes', 'gees', 'sees', 'seep', 'step']],
  ['crab', 'cake', 7, ['crab', 'crap', 'crop', 'coop', 'comp', 'camp', 'came', 'cake']],
  // Monday
  ['main', 'road', 5, ['main', 'lain', 'laid', 'loid', 'load', 'road']],
  ['vote', 'veto', 5, ['vote', 'rote', 'rete', 'rets', 'vets', 'veto']],
  ['lazy', 'loaf', 6, ['lazy', 'lacy', 'lack', 'lock', 'look', 'loof', 'loaf']],
  ['cold', 'play', 6, ['cold', 'bold', 'bolt', 'boat', 'blat', 'plat', 'play']],
  ['thug', 'life', 7, ['thug', 'thus', 'taus', 'tams', 'lams', 'lame', 'lime', 'life']],
  ['crab', 'cake', 7, ['crab', 'crap', 'crop', 'coop', 'comp', 'camp', 'came', 'cake']],
  ['quag', 'mire', 7, ['quag', 'quad', 'duad', 'dual', 'dial', 'dirl', 'dire', 'mire']],
  // Monday
  ['halo', 'ring', 5, ['halo', 'halt', 'hant', 'hang', 'rang', 'ring']],
  ['junk', 'mail', 5, ['junk', 'hunk', 'hank', 'haik', 'hail', 'mail']],
  ['tuna', 'fish', 6, ['tuna', 'luna', 'lunt', 'lint', 'list', 'fist', 'fish']],
  ['stag', 'solo', 6, ['stag', 'skag', 'skas', 'seas', 'sels', 'sols', 'solo']],
  ['hunk', 'stud', 7, ['hunk', 'huns', 'hues', 'hued', 'sued', 'sped', 'spud', 'stud']],
  ['wish', 'star', 7, ['wish', 'fish', 'fist', 'fest', 'feat', 'fear', 'sear', 'star']],
  ['stop', 'sign', 8, ['stop', 'step', 'seep', 'sees', 'sets', 'sits', 'sith', 'sigh', 'sign']],
  // Monday
  ['road', 'trip', 4, ['road', 'toad', 'trad', 'trap', 'trip']],
  ['gold', 'dust', 5, ['gold', 'bold', 'bolt', 'dolt', 'dost', 'dust']],
  ['ants', 'hill', 5, ['ants', 'aits', 'ails', 'fils', 'fill', 'hill']],
  ['cold', 'snow', 5, ['cold', 'colt', 'coot', 'soot', 'snot', 'snow']],
  ['when', 'ever', 6, ['when', 'then', 'thee', 'tyee', 'tyer', 'eyer', 'ever']],
  ['hand', 'soap', 6, ['hand', 'haed', 'hoed', 'hoer', 'hoar', 'soar', 'soap']],
  ['exit', 'ramp', 9, ['exit', 'emit', 'smit', 'shit', 'shin', 'sain', 'rain', 'rais', 'rams', 'ramp']],
  // Monday
  ['dawn', 'dusk', 4, ['dawn', 'dawk', 'dank', 'dunk', 'dusk']],
  ['blog', 'post', 5, ['blog', 'blot', 'boot', 'bort', 'port', 'post']],
  ['with', 'hold', 5, ['with', 'wite', 'wile', 'wild', 'wold', 'hold']],
  ['keep', 'lose', 5, ['keep', 'keet', 'leet', 'lest', 'lost', 'lose']],
  ['true', 'love', 6, ['true', 'tree', 'tyee', 'tyne', 'tone', 'lone', 'love']],
  ['area', 'code', 6, ['area', 'ares', 'tres', 'toes', 'tods', 'cods', 'code']],
  ['iamb', 'poet', 7, ['iamb', 'gamb', 'gamp', 'gasp', 'gast', 'past', 'post', 'poet']],
  // Monday
  ['dogs', 'bark', 4, ['dogs', 'bogs', 'bags', 'bars', 'bark']],
  ['fear', 'calm', 5, ['fear', 'feal', 'fell', 'cell', 'call', 'calm']],
  ['high', 'note', 5, ['high', 'sigh', 'sith', 'site', 'nite', 'note']],
  ['oaky', 'wine', 5, ['oaky', 'caky', 'cake', 'cane', 'cine', 'wine']],
  ['jean', 'gene', 5, ['jean', 'bean', 'bead', 'bend', 'bene', 'gene']],
  ['even', 'keel', 6, ['even', 'eves', 'eyes', 'pyes', 'pees', 'peel', 'keel']],
  ['nigh', 'near', 7, ['nigh', 'sigh', 'sith', 'sits', 'sets', 'seas', 'sear', 'near']],
  // Monday
  ['josh', 'joke', 4, ['josh', 'posh', 'pose', 'poke', 'joke']],
  ['trap', 'door', 5, ['trap', 'trop', 'prop', 'poop', 'poor', 'door']],
  ['vale', 'veil', 5, ['vale', 'bale', 'ball', 'bail', 'vail', 'veil']],
  ['math', 'whiz', 5, ['math', 'matt', 'watt', 'wait', 'whit', 'whiz']],
  ['wham', 'boom', 6, ['wham', 'cham', 'chat', 'coat', 'boat', 'boot', 'boom']],
  ['very', 'much', 6, ['very', 'vary', 'vars', 'mars', 'macs', 'mach', 'much']],
  ['stay', 'woke', 7, ['stay', 'stat', 'seat', 'sent', 'sene', 'sone', 'soke', 'woke']],
  // Monday
  ['soft', 'ware', 4, ['soft', 'sort', 'sore', 'wore', 'ware']],
  ['folk', 'song', 4, ['folk', 'holk', 'honk', 'hong', 'song']],
  ['rain', 'drop', 5, ['rain', 'gain', 'grin', 'grip', 'drip', 'drop']],
  ['grok', 'know', 5, ['grok', 'grow', 'glow', 'slow', 'snow', 'know']],
  ['palm', 'read', 5, ['palm', 'halm', 'helm', 'held', 'head', 'read']],
  ['gnaw', 'bite', 6, ['gnaw', 'gnat', 'goat', 'boat', 'bott', 'bitt', 'bite']],
  ['verb', 'noun', 7, ['verb', 'herb', 'hern', 'horn', 'born', 'boon', 'noon', 'noun']],
  // Monday
  ['gold', 'leaf', 4, ['gold', 'goad', 'load', 'lead', 'leaf']],
  ['yoke', 'yolk', 4, ['yoke', 'hoke', 'hole', 'holk', 'yolk']],
  ['half', 'moon', 5, ['half', 'hall', 'mall', 'moll', 'mool', 'moon']],
  ['gene', 'pool', 5, ['gene', 'gens', 'pens', 'pons', 'poos', 'pool']],
  ['lava', 'ooze', 5, ['lava', 'lave', 'laze', 'daze', 'doze', 'ooze']],
  ['club', 'soda', 6, ['club', 'slub', 'slur', 'sour', 'sous', 'sods', 'soda']],
  ['itch', 'skin', 9, ['itch', 'etch', 'each', 'bach', 'back', 'pack', 'paik', 'pain', 'sain', 'skin']],
  // Monday
  ['cold', 'feet', 4, ['cold', 'colt', 'celt', 'felt', 'feet']],
  ['tape', 'worm', 4, ['tape', 'tare', 'tore', 'wore', 'worm']],
  ['loch', 'ness', 5, ['loch', 'loth', 'lots', 'lets', 'less', 'ness']],
  ['next', 'time', 5, ['next', 'text', 'tent', 'tint', 'tine', 'time']],
  ['tram', 'stop', 6, ['tram', 'cram', 'cham', 'chap', 'chop', 'shop', 'stop']],
  ['veep', 'exec', 6, ['veep', 'beep', 'bees', 'byes', 'eyes', 'exes', 'exec']],
  ['elan', 'musk', 7, ['elan', 'flan', 'flat', 'fiat', 'fist', 'mist', 'must', 'musk']],
  // Monday
  ['best', 'mate', 4, ['best', 'bast', 'base', 'bate', 'mate']],
  ['mini', 'mart', 4, ['mini', 'mina', 'mana', 'mara', 'mart']],
  ['last', 'shot', 4, ['last', 'lost', 'loot', 'soot', 'shot']],
  ['city', 'girl', 5, ['city', 'cite', 'cire', 'dire', 'dirl', 'girl']],
  ['help', 'desk', 6, ['help', 'hell', 'hull', 'hulk', 'husk', 'dusk', 'desk']],
  ['pita', 'chip', 6, ['pita', 'pits', 'pots', 'pois', 'phis', 'chis', 'chip']],
  ['bake', 'shop', 7, ['bake', 'bale', 'bole', 'bolt', 'boot', 'soot', 'shot', 'shop']],
  // Monday
  ['half', 'time', 4, ['half', 'hale', 'hame', 'tame', 'time']],
  ['salt', 'lick', 4, ['salt', 'silt', 'silk', 'sick', 'lick']],
  ['myth', 'fact', 5, ['myth', 'math', 'mach', 'mace', 'face', 'fact']],
  ['cold', 'beer', 5, ['cold', 'bold', 'bolt', 'belt', 'beet', 'beer']],
  ['flag', 'pole', 6, ['flag', 'flam', 'foam', 'form', 'fore', 'pore', 'pole']],
  ['item', 'list', 7, ['item', 'stem', 'seem', 'deem', 'deet', 'leet', 'lest', 'list']],
  ['take', 'away', 7, ['take', 'make', 'mare', 'mire', 'miry', 'airy', 'awry', 'away']],
  // Monday
  ['tail', 'gate', 4, ['tail', 'tall', 'gall', 'gale', 'gate']],
  ['curb', 'side', 4, ['curb', 'cure', 'cire', 'sire', 'side']],
  ['giga', 'watt', 5, ['giga', 'gaga', 'gags', 'gats', 'wats', 'watt']],
  ['care', 'free', 5, ['care', 'tare', 'tyre', 'tyee', 'tree', 'free']],
  ['sole', 'heir', 6, ['sole', 'bole', 'boll', 'bell', 'hell', 'heil', 'heir']],
  ['grid', 'lock', 7, ['grid', 'grad', 'brad', 'bead', 'beak', 'beck', 'bock', 'lock']],
  ['peso', 'quid', 7, ['peso', 'pest', 'best', 'bust', 'dust', 'duit', 'quit', 'quid']],
  // Monday
  ['wine', 'cask', 4, ['wine', 'cine', 'cane', 'case', 'cask']],
  ['pain', 'heal', 4, ['pain', 'pail', 'hail', 'heil', 'heal']],
  ['cold', 'brew', 4, ['cold', 'coed', 'cred', 'bred', 'brew']],
  ['dial', 'tone', 5, ['dial', 'dill', 'doll', 'dole', 'done', 'tone']],
  ['wash', 'fold', 6, ['wash', 'bash', 'base', 'bale', 'bald', 'bold', 'fold']],
  ['jury', 'seat', 6, ['jury', 'bury', 'busy', 'bust', 'best', 'beat', 'seat']],
  ['golf', 'club', 7, ['golf', 'goof', 'goon', 'boon', 'boob', 'blob', 'blub', 'club']],
  // Monday
  ['bone', 'cast', 4, ['bone', 'bane', 'base', 'bast', 'cast']],
  ['poke', 'bowl', 4, ['poke', 'hoke', 'howe', 'howl', 'bowl']],
  ['dove', 'tail', 5, ['dove', 'dole', 'dale', 'tale', 'tall', 'tail']],
  ['jack', 'jill', 5, ['jack', 'back', 'balk', 'ball', 'bill', 'jill']],
  ['leap', 'frog', 6, ['leap', 'heap', 'heat', 'feat', 'frat', 'frag', 'frog']],
  ['ruse', 'ploy', 7, ['ruse', 'rose', 'pose', 'pome', 'pomp', 'poop', 'plop', 'ploy']],
  ['game', 'plan', 7, ['game', 'fame', 'fare', 'fire', 'firn', 'pirn', 'pian', 'plan']],
  // Monday
  ['belt', 'loop', 4, ['belt', 'bolt', 'boot', 'loot', 'loop']],
  ['boat', 'crew', 4, ['boat', 'brat', 'braw', 'brew', 'crew']],
  ['frog', 'pond', 4, ['frog', 'prog', 'prod', 'pood', 'pond']],
  ['gift', 'shop', 5, ['gift', 'sift', 'soft', 'soot', 'shot', 'shop']],
  ['fall', 'down', 5, ['fall', 'fail', 'fain', 'fawn', 'dawn', 'down']],
  ['leek', 'soup', 6, ['leek', 'leak', 'lead', 'leud', 'loud', 'loup', 'soup']],
  ['snow', 'days', 7, ['snow', 'show', 'shew', 'shes', 'sees', 'dees', 'deys', 'days']],
  // Monday
  ['good', 'will', 4, ['good', 'gold', 'gild', 'gill', 'will']],
  ['were', 'wolf', 4, ['were', 'wore', 'word', 'wold', 'wolf']],
  ['knap', 'sack', 5, ['knap', 'snap', 'soap', 'soak', 'sock', 'sack']],
  ['shin', 'bone', 5, ['shin', 'chin', 'coin', 'conn', 'cone', 'bone']],
  ['prom', 'king', 6, ['prom', 'prod', 'pood', 'pond', 'pong', 'ping', 'king']],
  ['shoe', 'lace', 7, ['shoe', 'shot', 'soot', 'loot', 'look', 'lock', 'lack', 'lace']],
  ['cork', 'plug', 7, ['cork', 'bork', 'bort', 'bout', 'gout', 'glut', 'glug', 'plug']],
  // Monday
  ['pond', 'lake', 4, ['pond', 'pone', 'lone', 'lane', 'lake']],
  ['door', 'lock', 4, ['door', 'boor', 'book', 'bock', 'lock']],
  ['mini', 'coop', 5, ['mini', 'mink', 'monk', 'conk', 'cook', 'coop']],
  ['kiss', 'tell', 5, ['kiss', 'kins', 'kens', 'tens', 'tels', 'tell']],
  ['jete', 'jump', 6, ['jete', 'fete', 'feme', 'heme', 'hemp', 'hump', 'jump']],
  ['open', 'mind', 6, ['open', 'oped', 'sped', 'seed', 'meed', 'mend', 'mind']],
  ['rick', 'shaw', 7, ['rick', 'pick', 'peck', 'peak', 'peat', 'phat', 'shat', 'shaw']],
  // Monday
  ['bald', 'head', 4, ['bald', 'band', 'bend', 'bead', 'head']],
  ['hard', 'soft', 4, ['hard', 'sard', 'sord', 'sort', 'soft']],
  ['wind', 'down', 5, ['wind', 'wins', 'dins', 'dons', 'dows', 'down']],
  ['link', 'join', 5, ['link', 'linn', 'lion', 'loon', 'loin', 'join']],
  ['menu', 'list', 5, ['menu', 'genu', 'gent', 'gest', 'gist', 'list']],
  ['jury', 'duty', 6, ['jury', 'bury', 'burl', 'bull', 'dull', 'duly', 'duty']],
  ['life', 'span', 7, ['life', 'lice', 'sice', 'sics', 'secs', 'seas', 'spas', 'span']],
  // Monday
  ['moon', 'beam', 4, ['moon', 'moan', 'mean', 'bean', 'beam']],
  ['fall', 'leaf', 4, ['fall', 'fell', 'feal', 'leal', 'leaf']],
  ['bury', 'seed', 4, ['bury', 'burd', 'surd', 'sued', 'seed']],
  ['ship', 'yard', 5, ['ship', 'chip', 'chid', 'caid', 'card', 'yard']],
  ['desk', 'lamp', 6, ['desk', 'disk', 'diss', 'dims', 'dams', 'damp', 'lamp']],
  ['glug', 'beer', 6, ['glug', 'gleg', 'gled', 'bled', 'blet', 'beet', 'beer']],
  ['ship', 'helm', 7, ['ship', 'chip', 'chop', 'coop', 'hoop', 'holp', 'help', 'helm']],
  // Monday
  ['back', 'door', 4, ['back', 'bock', 'book', 'boor', 'door']],
  ['chat', 'room', 4, ['chat', 'coat', 'coot', 'root', 'room']],
  ['lame', 'duck', 4, ['lame', 'dame', 'dace', 'duce', 'duck']],
  ['fort', 'nite', 5, ['fort', 'bort', 'bott', 'bitt', 'bite', 'nite']],
  ['mine', 'ours', 5, ['mine', 'bine', 'bins', 'buns', 'burs', 'ours']],
  ['wing', 'flap', 7, ['wing', 'ling', 'lint', 'list', 'fist', 'fiat', 'flat', 'flap']],
  ['cold', 'numb', 8, ['cold', 'bold', 'bola', 'bora', 'bura', 'dura', 'duma', 'dumb', 'numb']],
  // Monday
  ['foot', 'ball', 4, ['foot', 'boot', 'bolt', 'boll', 'ball']],
  ['hula', 'hoop', 4, ['hula', 'hulk', 'holk', 'holp', 'hoop']],
  ['lamp', 'post', 5, ['lamp', 'gamp', 'gasp', 'gast', 'past', 'post']],
  ['dumb', 'luck', 5, ['dumb', 'duma', 'luma', 'luna', 'lunk', 'luck']],
  ['blue', 'chip', 6, ['blue', 'blae', 'blam', 'clam', 'cham', 'chap', 'chip']],
  ['slam', 'dunk', 6, ['slam', 'seam', 'seat', 'sent', 'dent', 'dunt', 'dunk']],
  ['scar', 'face', 7, ['scar', 'scat', 'seat', 'feat', 'fest', 'fast', 'fact', 'face']],
  // Monday
  ['hive', 'mind', 4, ['hive', 'dive', 'dine', 'mine', 'mind']],
  ['some', 'many', 4, ['some', 'same', 'sane', 'mane', 'many']],
  ['stag', 'deer', 4, ['stag', 'star', 'sear', 'dear', 'deer']],
  ['tide', 'pool', 5, ['tide', 'tile', 'pile', 'pill', 'poll', 'pool']],
  ['fool', 'jest', 5, ['fool', 'foal', 'feal', 'feat', 'fest', 'jest']],
  ['work', 'play', 6, ['work', 'bork', 'bort', 'boat', 'blat', 'plat', 'play']],
  ['inch', 'foot', 9, ['inch', 'itch', 'etch', 'each', 'bach', 'back', 'bock', 'book', 'boot', 'foot']],
  // Monday
  ['foot', 'yard', 4, ['foot', 'food', 'ford', 'fard', 'yard']],
  ['well', 'done', 4, ['well', 'dell', 'dele', 'dene', 'done']],
  ['door', 'bell', 5, ['door', 'boor', 'boot', 'bolt', 'belt', 'bell']],
  ['head', 'line', 5, ['head', 'bead', 'bend', 'bene', 'bine', 'line']],
  ['whip', 'lash', 5, ['whip', 'whit', 'wait', 'wast', 'last', 'lash']],
  ['farm', 'crop', 6, ['farm', 'fart', 'fort', 'foot', 'coot', 'coop', 'crop']],
  ['turn', 'away', 7, ['turn', 'tarn', 'karn', 'kirn', 'airn', 'airy', 'awry', 'away']],
  // Monday
  ['coin', 'toss', 4, ['coin', 'conn', 'cons', 'coss', 'toss'], '⚽', "World Cup", 'darkred'],
  ['goal', 'post', 4, ['goal', 'coal', 'coat', 'cost', 'post'], '⚽', "World Cup", 'darkred'],
  ['head', 'butt', 5, ['head', 'bead', 'beat', 'bent', 'bunt', 'butt'], '⚽', "World Cup", 'darkred'],
  ['home', 'team', 5, ['home', 'heme', 'here', 'herm', 'term', 'team'], '⚽', "World Cup", 'darkred'],
  ['shot', 'save', 6, ['shot', 'shat', 'seat', 'sent', 'sene', 'sane', 'save'], '⚽', "World Cup", 'darkred'],
  ['free', 'kick', 6, ['free', 'fret', 'feet', 'keet', 'keek', 'keck', 'kick'], '⚽', "World Cup", 'darkred'],
  ['show', 'time', 7, ['show', 'chow', 'chon', 'cion', 'lion', 'limn', 'lime', 'time'], '⚽', "World Cup", 'darkred'],
  // Monday
  ['rose', 'gold', 4, ['rose', 'hose', 'hole', 'hold', 'gold']],
  ['fire', 'wood', 4, ['fire', 'fore', 'ford', 'food', 'wood']],
  ['reel', 'fish', 5, ['reel', 'feel', 'feet', 'fest', 'fist', 'fish']],
  ['keep', 'calm', 5, ['keep', 'kelp', 'help', 'helm', 'halm', 'calm']],
  ['chip', 'monk', 5, ['chip', 'chin', 'coin', 'conn', 'conk', 'monk']],
  ['meet', 'cute', 6, ['meet', 'beet', 'bees', 'bets', 'buts', 'bute', 'cute']],
  ['snow', 'fall', 7, ['snow', 'show', 'shot', 'shut', 'shul', 'saul', 'sall', 'fall']],
  // Monday
  ['port', 'wine', 4, ['port', 'pore', 'pone', 'pine', 'wine']],
  ['cell', 'mate', 4, ['cell', 'call', 'mall', 'male', 'mate']],
  ['deal', 'sold', 5, ['deal', 'dead', 'head', 'held', 'hold', 'sold']],
  ['grow', 'seed', 5, ['grow', 'glow', 'slow', 'slew', 'sled', 'seed']],
  ['snow', 'caps', 6, ['snow', 'snot', 'soot', 'coot', 'coos', 'cops', 'caps']],
  ['gift', 'wrap', 6, ['gift', 'gist', 'fist', 'fiat', 'frat', 'frap', 'wrap']],
  ['avid', 'keen', 7, ['avid', 'acid', 'aced', 'ahed', 'shed', 'seed', 'seen', 'keen']],
  // Monday
  ['hard', 'mode', 4, ['hard', 'hare', 'hade', 'made', 'mode']],
  ['wine', 'cork', 4, ['wine', 'cine', 'cire', 'core', 'cork']],
  ['nose', 'goes', 4, ['nose', 'dose', 'doss', 'does', 'goes']],
  ['shop', 'lift', 5, ['shop', 'shot', 'soot', 'loot', 'loft', 'lift']],
  ['rent', 'free', 5, ['rent', 'bent', 'beet', 'feet', 'fret', 'free']],
  ['rain', 'pour', 6, ['rain', 'cain', 'coin', 'coon', 'poon', 'poor', 'pour']],
  ['snub', 'deny', 7, ['snub', 'slub', 'flub', 'flus', 'feus', 'fens', 'dens', 'deny']],
  // Monday
  ['lull', 'hush', 4, ['lull', 'hull', 'hulk', 'husk', 'hush']],
  ['fire', 'burn', 4, ['fire', 'cire', 'cure', 'curn', 'burn']],
  ['take', 'down', 5, ['take', 'tame', 'dame', 'damn', 'dawn', 'down']],
  ['fish', 'pond', 5, ['fish', 'pish', 'piss', 'pins', 'pons', 'pond']],
  ['beer', 'pong', 5, ['beer', 'bees', 'bens', 'pens', 'pons', 'pong']],
  ['sand', 'trap', 6, ['sand', 'band', 'bend', 'bead', 'brad', 'trad', 'trap']],
  ['abut', 'meet', 7, ['abut', 'abet', 'abed', 'ahed', 'shed', 'seed', 'meed', 'meet']],
  // Monday
  ['home', 'body', 4, ['home', 'come', 'code', 'bode', 'body']],
  ['dine', 'dash', 4, ['dine', 'dink', 'disk', 'dish', 'dash']],
  ['snow', 'melt', 5, ['snow', 'snot', 'soot', 'moot', 'molt', 'melt']],
  ['plea', 'deal', 5, ['plea', 'pled', 'peed', 'deed', 'dead', 'deal']],
  ['stay', 'here', 6, ['stay', 'spay', 'spas', 'seas', 'sers', 'hers', 'here']],
  ['good', 'idea', 6, ['good', 'pood', 'plod', 'pled', 'plea', 'ilea', 'idea']],
  ['chai', 'herb', 7, ['chai', 'chad', 'chid', 'caid', 'card', 'hard', 'herd', 'herb']],
  // Monday
  ['sand', 'dune', 4, ['sand', 'land', 'lane', 'lune', 'dune']],
  ['moon', 'glow', 4, ['moon', 'goon', 'goop', 'glop', 'glow']],
  ['chow', 'down', 5, ['chow', 'chon', 'coon', 'goon', 'gown', 'down']],
  ['visa', 'card', 5, ['visa', 'vasa', 'casa', 'case', 'care', 'card']],
  ['slim', 'none', 6, ['slim', 'slid', 'said', 'sand', 'sane', 'sone', 'none']],
  ['warp', 'wrap', 6, ['warp', 'warn', 'wain', 'whin', 'whip', 'whap', 'wrap']],
  ['abut', 'join', 10, ['abut', 'abet', 'abed', 'ahed', 'ahem', 'them', 'then', 'thin', 'chin', 'coin', 'join']],
  // Monday
  ['left', 'lane', 4, ['left', 'lent', 'lend', 'land', 'lane']],
  ['drum', 'beat', 4, ['drum', 'dram', 'drat', 'brat', 'beat']],
  ['stow', 'away', 4, ['stow', 'staw', 'stay', 'sway', 'away']],
  ['gate', 'open', 5, ['gate', 'gats', 'oats', 'opts', 'opes', 'open']],
  ['clay', 'pots', 6, ['clay', 'clad', 'clod', 'plod', 'pood', 'poos', 'pots']],
  ['navy', 'seal', 6, ['navy', 'nave', 'save', 'sale', 'sall', 'sell', 'seal']],
  ['play', 'mate', 7, ['play', 'plan', 'pean', 'peas', 'pets', 'pats', 'mats', 'mate']],
  // Monday
  ['rock', 'star', 4, ['rock', 'sock', 'soak', 'soar', 'star']],
  ['book', 'shop', 4, ['book', 'boot', 'soot', 'shot', 'shop']],
  ['doge', 'coin', 4, ['doge', 'done', 'cone', 'conn', 'coin']],
  ['blue', 'moon', 5, ['blue', 'blub', 'blob', 'boob', 'boon', 'moon']],
  ['shoe', 'horn', 5, ['shoe', 'shot', 'soot', 'soon', 'sorn', 'horn']],
  ['ante', 'risk', 6, ['ante', 'ants', 'aits', 'ains', 'rins', 'rink', 'risk']],
  ['star', 'gaze', 7, ['star', 'sear', 'seal', 'sell', 'sall', 'gall', 'gale', 'gaze']],
  // Monday
  ['want', 'more', 4, ['want', 'wane', 'mane', 'mare', 'more']],
  ['love', 'kiss', 4, ['love', 'lose', 'loss', 'koss', 'kiss']],
  ['font', 'size', 5, ['font', 'fond', 'find', 'fine', 'sine', 'size']],
  ['bait', 'shop', 5, ['bait', 'wait', 'whit', 'shit', 'ship', 'shop']],
  ['glow', 'worm', 5, ['glow', 'glop', 'goop', 'gorp', 'gorm', 'worm']],
  ['tram', 'card', 6, ['tram', 'cram', 'cham', 'chad', 'chid', 'caid', 'card']],
  ['game', 'show', 7, ['game', 'came', 'camp', 'comp', 'coop', 'chop', 'chow', 'show']],
  // Monday
  ['veto', 'laws', 4, ['veto', 'vets', 'lets', 'lats', 'laws']],
  ['pint', 'beer', 4, ['pint', 'bint', 'bent', 'beet', 'beer']],
  ['some', 'body', 4, ['some', 'come', 'code', 'bode', 'body']],
  ['yoga', 'pose', 5, ['yoga', 'toga', 'tola', 'tole', 'pole', 'pose']],
  ['fawn', 'deer', 5, ['fawn', 'dawn', 'daws', 'dews', 'dees', 'deer']],
  ['star', 'trek', 6, ['star', 'sear', 'seas', 'sees', 'tees', 'tres', 'trek']],
  ['halo', 'glow', 7, ['halo', 'hale', 'hole', 'holp', 'hoop', 'goop', 'glop', 'glow']],
  // Monday
  ['roof', 'deck', 4, ['roof', 'rook', 'rock', 'dock', 'deck']],
  ['last', 'call', 4, ['last', 'cast', 'cart', 'carl', 'call']],
  ['pile', 'heap', 5, ['pile', 'pele', 'pelt', 'peat', 'heat', 'heap']],
  ['chia', 'seed', 5, ['chia', 'chid', 'chad', 'shad', 'shed', 'seed']],
  ['oath', 'bond', 5, ['oath', 'bath', 'bate', 'bane', 'band', 'bond']],
  ['craw', 'fish', 6, ['craw', 'braw', 'brat', 'frat', 'fiat', 'fist', 'fish']],
  ['high', 'tail', 8, ['high', 'sigh', 'sidh', 'side', 'sade', 'sale', 'sall', 'sail', 'tail']],
  // Monday
  ['meat', 'ball', 4, ['meat', 'beat', 'belt', 'bell', 'ball']],
  ['hair', 'clip', 4, ['hair', 'fair', 'flir', 'flip', 'clip']],
  ['pump', 'kins', 5, ['pump', 'bump', 'bums', 'buns', 'bins', 'kins']],
  ['mood', 'ring', 5, ['mood', 'food', 'fond', 'find', 'rind', 'ring']],
  ['rich', 'lush', 5, ['rich', 'rice', 'rise', 'ruse', 'rush', 'lush']],
  ['drum', 'roll', 6, ['drum', 'doum', 'doom', 'room', 'roof', 'rolf', 'roll']],
  ['hype', 'plug', 8, ['hype', 'hope', 'hops', 'sops', 'sous', 'sour', 'slur', 'slug', 'plug']],
  // Monday
  ['fate', 'luck', 4, ['fate', 'face', 'lace', 'lack', 'luck']],
  ['bowl', 'soup', 4, ['bowl', 'boil', 'soil', 'soul', 'soup']],
  ['lime', 'zest', 5, ['lime', 'lame', 'lase', 'last', 'lest', 'zest']],
  ['grip', 'hold', 5, ['grip', 'grid', 'grad', 'goad', 'gold', 'hold']],
  ['dear', 'love', 6, ['dear', 'dead', 'lead', 'load', 'lord', 'lore', 'love']],
  ['send', 'emit', 6, ['send', 'sand', 'said', 'skid', 'skit', 'smit', 'emit']],
  ['army', 'navy', 8, ['army', 'arms', 'aims', 'ains', 'wins', 'wans', 'wany', 'wavy', 'navy']],
  // Monday
  ['back', 'rear', 4, ['back', 'beck', 'beak', 'bear', 'rear']],
  ['duke', 'lord', 4, ['duke', 'dure', 'dore', 'lore', 'lord']],
  ['lend', 'give', 5, ['lend', 'fend', 'find', 'fine', 'five', 'give']],
  ['dock', 'pier', 5, ['dock', 'deck', 'peck', 'peek', 'peer', 'pier']],
  ['cast', 'crew', 6, ['cast', 'cart', 'card', 'cord', 'coed', 'cred', 'crew']],
  ['flap', 'jack', 6, ['flap', 'slap', 'soap', 'soak', 'sock', 'jock', 'jack']],
  ['demo', 'show', 8, ['demo', 'deco', 'deck', 'dock', 'sock', 'sook', 'soot', 'shot', 'show']],
  // Monday
  ['lute', 'harp', 4, ['lute', 'late', 'hate', 'hare', 'harp']],
  ['send', 'mail', 4, ['send', 'sand', 'said', 'maid', 'mail']],
  ['cove', 'gulf', 5, ['cove', 'cole', 'cold', 'gold', 'golf', 'gulf']],
  ['star', 'buck', 5, ['star', 'sear', 'bear', 'beak', 'beck', 'buck']],
  ['swim', 'pool', 6, ['swim', 'slim', 'slip', 'slop', 'plop', 'poop', 'pool']],
  ['leaf', 'twig', 6, ['leaf', 'leak', 'teak', 'teat', 'twat', 'twit', 'twig']],
  ['acid', 'burn', 7, ['acid', 'aced', 'ahed', 'shed', 'sued', 'surd', 'burd', 'burn']],
  // Monday
  ['mice', 'rats', 4, ['mice', 'mace', 'macs', 'mats', 'rats']],
  ['keep', 'hold', 4, ['keep', 'kelp', 'help', 'held', 'hold']],
  ['song', 'beat', 5, ['song', 'bong', 'bond', 'bend', 'bead', 'beat']],
  ['itch', 'rash', 5, ['itch', 'etch', 'each', 'bach', 'bash', 'rash']],
  ['diet', 'coke', 5, ['diet', 'dint', 'dine', 'cine', 'cone', 'coke']],
  ['lava', 'melt', 6, ['lava', 'lama', 'mama', 'mara', 'mart', 'malt', 'melt']],
  ['hint', 'clue', 7, ['hint', 'bint', 'bent', 'beat', 'blat', 'blae', 'blue', 'clue']],
  // Monday
  ['flex', 'bend', 4, ['flex', 'fled', 'feed', 'fend', 'bend']],
  ['palm', 'hand', 4, ['palm', 'balm', 'bald', 'band', 'hand']],
  ['rest', 'doze', 5, ['rest', 'hest', 'host', 'dost', 'dose', 'doze']],
  ['team', 'club', 5, ['team', 'beam', 'blam', 'blab', 'blub', 'club']],
  ['hair', 'trim', 6, ['hair', 'fair', 'fain', 'gain', 'grin', 'grim', 'trim']],
  ['hope', 'pray', 6, ['hope', 'hops', 'bops', 'boas', 'bras', 'bray', 'pray']],
  ['mite', 'flea', 7, ['mite', 'gite', 'gits', 'gies', 'gied', 'gled', 'fled', 'flea']],
  // Monday
  ['bill', 'debt', 4, ['bill', 'bell', 'belt', 'delt', 'debt']],
  ['lion', 'roar', 4, ['lion', 'loon', 'loan', 'roan', 'roar']],
  ['jump', 'dive', 5, ['jump', 'dump', 'damp', 'dame', 'dime', 'dive']],
  ['stop', 'halt', 5, ['stop', 'stot', 'soot', 'hoot', 'holt', 'halt']],
  ['pull', 'drag', 6, ['pull', 'dull', 'doll', 'dolt', 'doat', 'drat', 'drag']],
  ['task', 'duty', 6, ['task', 'tass', 'tats', 'tots', 'dots', 'doty', 'duty']],
  ['ooze', 'drip', 8, ['ooze', 'doze', 'daze', 'dare', 'darb', 'daub', 'drub', 'drib', 'drip']],
  // Monday
  ['seek', 'find', 4, ['seek', 'seed', 'feed', 'fend', 'find']],
  ['both', 'same', 4, ['both', 'bath', 'bate', 'sate', 'same']],
  ['reed', 'pipe', 5, ['reed', 'peed', 'pees', 'peps', 'pips', 'pipe']],
  ['golf', 'putt', 5, ['golf', 'gulf', 'guls', 'guts', 'puts', 'putt']],
  ['next', 'door', 6, ['next', 'neat', 'beat', 'bear', 'boar', 'boor', 'door']],
  ['cube', 'boxy', 6, ['cube', 'cubs', 'bubs', 'bobs', 'bods', 'body', 'boxy']],
  ['navy', 'blue', 9, ['navy', 'nary', 'nard', 'bard', 'barm', 'berm', 'beam', 'blam', 'blae', 'blue']],
  // Monday
  ['tape', 'film', 4, ['tape', 'tale', 'tile', 'file', 'film']],
  ['soap', 'foam', 4, ['soap', 'slap', 'flap', 'flam', 'foam']],
  ['mini', 'golf', 5, ['mini', 'mind', 'mild', 'gild', 'gold', 'golf']],
  ['hall', 'room', 5, ['hall', 'halt', 'holt', 'hoot', 'root', 'room']],
  ['shed', 'lose', 6, ['shed', 'seed', 'sees', 'lees', 'less', 'loss', 'lose']],
  ['rung', 'step', 6, ['rung', 'dung', 'dunt', 'duet', 'suet', 'stet', 'step']],
  ['snug', 'cozy', 7, ['snug', 'snog', 'snot', 'soot', 'coot', 'colt', 'coly', 'cozy']],
  // Monday
  ['tide', 'tied', 4, ['tide', 'tile', 'tils', 'ties', 'tied']],
  ['poof', 'gone', 4, ['poof', 'pood', 'pond', 'pone', 'gone']],
  ['rump', 'rear', 5, ['rump', 'hump', 'hemp', 'heap', 'hear', 'rear']],
  ['bird', 'nest', 5, ['bird', 'bind', 'bend', 'bent', 'best', 'nest']],
  ['fear', 'love', 6, ['fear', 'feat', 'fest', 'lest', 'lost', 'lose', 'love']],
  ['ouch', 'hurt', 6, ['ouch', 'much', 'muck', 'huck', 'hunk', 'hunt', 'hurt']],
  ['trod', 'walk', 7, ['trod', 'prod', 'pood', 'pool', 'poll', 'pall', 'wall', 'walk']],
  // Monday
  ['pink', 'rose', 4, ['pink', 'pine', 'pone', 'pose', 'rose']],
  ['gear', 'part', 4, ['gear', 'pear', 'peat', 'pert', 'part']],
  ['shoe', 'heel', 5, ['shoe', 'shod', 'shed', 'seed', 'heed', 'heel']],
  ['then', 'next', 5, ['then', 'than', 'that', 'teat', 'neat', 'next']],
  ['crop', 'land', 6, ['crop', 'chop', 'chip', 'chid', 'caid', 'laid', 'land']],
  ['join', 'team', 6, ['join', 'coin', 'corn', 'torn', 'tern', 'term', 'team']],
  ['skin', 'hide', 7, ['skin', 'sain', 'cain', 'carn', 'care', 'cade', 'hade', 'hide']],
  // Monday
  ['skit', 'play', 4, ['skit', 'skat', 'slat', 'plat', 'play']],
  ['came', 'went', 4, ['came', 'cane', 'cant', 'cent', 'went']],
  ['post', 'mail', 5, ['post', 'most', 'mast', 'malt', 'mall', 'mail']],
  ['pear', 'plum', 5, ['pear', 'sear', 'seam', 'slam', 'slum', 'plum']],
  ['fair', 'just', 6, ['fair', 'fail', 'bail', 'bait', 'bast', 'bust', 'just']],
  ['plum', 'tart', 6, ['plum', 'glum', 'glut', 'gout', 'tout', 'taut', 'tart']],
  ['dirt', 'scum', 7, ['dirt', 'diet', 'deet', 'deem', 'seem', 'seam', 'scam', 'scum']],
  // Monday
  ['tame', 'mild', 4, ['tame', 'tale', 'male', 'mile', 'mild']],
  ['cram', 'test', 4, ['cram', 'tram', 'team', 'teat', 'test']],
  ['sort', 'kind', 5, ['sort', 'fort', 'font', 'fond', 'find', 'kind']],
  ['deer', 'buck', 5, ['deer', 'beer', 'bear', 'beak', 'beck', 'buck']],
  ['dirt', 'soil', 6, ['dirt', 'dart', 'fart', 'farl', 'fail', 'foil', 'soil']],
  ['trig', 'math', 6, ['trig', 'brig', 'brit', 'bait', 'batt', 'bath', 'math']],
  ['move', 'flow', 7, ['move', 'cove', 'come', 'comp', 'coop', 'clop', 'flop', 'flow']],
  // Monday
  ['tusk', 'fang', 4, ['tusk', 'task', 'tank', 'tang', 'fang']],
  ['half', 'pint', 4, ['half', 'halt', 'hant', 'hint', 'pint']],
  ['cape', 'hood', 5, ['cape', 'cope', 'cole', 'cold', 'hold', 'hood']],
  ['hope', 'wish', 5, ['hope', 'hops', 'hips', 'hiss', 'wiss', 'wish']],
  ['reel', 'spin', 6, ['reel', 'heel', 'heil', 'hail', 'sail', 'sain', 'spin']],
  ['tree', 'bush', 6, ['tree', 'tres', 'toes', 'toss', 'boss', 'bosh', 'bush']],
  ['flag', 'wave', 7, ['flag', 'flam', 'foam', 'form', 'farm', 'fare', 'fave', 'wave']],
  // Monday
  ['read', 'scan', 4, ['read', 'ream', 'seam', 'scam', 'scan']],
  ['boom', 'bust', 4, ['boom', 'book', 'bosk', 'busk', 'bust']],
  ['ward', 'zone', 5, ['ward', 'bard', 'band', 'bane', 'bone', 'zone']],
  ['mask', 'veil', 5, ['mask', 'mark', 'marl', 'mail', 'vail', 'veil']],
  ['tram', 'ride', 6, ['tram', 'team', 'ream', 'read', 'redd', 'rede', 'ride']],
  ['fake', 'sham', 7, ['fake', 'bake', 'bare', 'barm', 'berm', 'beam', 'seam', 'sham']],
  ['over', 'cast', 8, ['over', 'oyer', 'dyer', 'deer', 'beer', 'beet', 'best', 'bast', 'cast']],
  // Monday
  ['thaw', 'melt', 4, ['thaw', 'that', 'teat', 'meat', 'melt']],
  ['plat', 'form', 4, ['plat', 'flat', 'flam', 'foam', 'form']],
  ['spam', 'mail', 5, ['spam', 'span', 'spin', 'sain', 'main', 'mail']],
  ['card', 'deck', 5, ['card', 'bard', 'bark', 'back', 'beck', 'deck']],
  ['wise', 'fool', 6, ['wise', 'mise', 'mile', 'mill', 'moll', 'mool', 'fool']],
  ['tsar', 'king', 6, ['tsar', 'tear', 'teas', 'keas', 'kens', 'kins', 'king']],
  ['tied', 'knot', 7, ['tied', 'hied', 'hoed', 'hood', 'hoot', 'soot', 'snot', 'knot']],
  // Monday
  ['sole', 'foot', 4, ['sole', 'bole', 'bolt', 'boot', 'foot']],
  ['much', 'many', 4, ['much', 'mach', 'mace', 'mane', 'many']],
  ['bell', 'gong', 5, ['bell', 'boll', 'bold', 'bond', 'bong', 'gong']],
  ['lily', 'rose', 5, ['lily', 'lilt', 'list', 'lost', 'lose', 'rose']],
  ['swan', 'duck', 6, ['swan', 'swap', 'soap', 'soak', 'sock', 'dock', 'duck']],
  ['trip', 'fall', 6, ['trip', 'grip', 'grin', 'gain', 'fain', 'fail', 'fall']],
  ['bite', 'chew', 7, ['bite', 'bike', 'tike', 'tyke', 'tyee', 'thee', 'thew', 'chew']],
  // Monday
  ['lark', 'song', 4, ['lark', 'lank', 'lang', 'long', 'song']],
  ['muse', 'poet', 4, ['muse', 'must', 'most', 'post', 'poet']],
  ['vase', 'bowl', 5, ['vase', 'base', 'bale', 'ball', 'bawl', 'bowl']],
  ['tree', 'wood', 5, ['tree', 'tres', 'toes', 'woes', 'woos', 'wood']],
  ['veto', 'stop', 6, ['veto', 'vets', 'sets', 'sees', 'seep', 'step', 'stop']],
  ['dome', 'arch', 6, ['dome', 'dime', 'dims', 'aims', 'arms', 'arcs', 'arch']],
  ['gulp', 'swig', 8, ['gulp', 'gull', 'gall', 'tall', 'tail', 'tain', 'twin', 'twig', 'swig']],
  // Monday
  ['myth', 'tale', 4, ['myth', 'math', 'mate', 'male', 'tale']],
  ['rest', 'easy', 4, ['rest', 'best', 'bast', 'east', 'easy']],
  ['dart', 'rush', 5, ['dart', 'cart', 'cast', 'cash', 'rash', 'rush']],
  ['rock', 'sway', 5, ['rock', 'sock', 'soak', 'soap', 'swap', 'sway']],
  ['peer', 'gaze', 6, ['peer', 'pees', 'gees', 'gaes', 'gags', 'gage', 'gaze']],
  ['jazz', 'band', 6, ['jazz', 'razz', 'raze', 'faze', 'fane', 'bane', 'band']],
  ['fish', 'swim', 7, ['fish', 'fist', 'fest', 'feat', 'seat', 'seam', 'swam', 'swim']],
  // Monday
  ['port', 'side', 4, ['port', 'pore', 'sore', 'sire', 'side']],
  ['cool', 'chic', 4, ['cool', 'coil', 'coin', 'chin', 'chic']],
  ['fuel', 'tank', 5, ['fuel', 'full', 'fall', 'tall', 'talk', 'tank']],
  ['thud', 'bang', 5, ['thud', 'thus', 'taus', 'tans', 'bans', 'bang']],
  ['keep', 'save', 6, ['keep', 'keel', 'seel', 'sell', 'sall', 'sale', 'save']],
  ['whig', 'tory', 6, ['whig', 'whin', 'chin', 'coin', 'corn', 'cory', 'tory']],
  ['gush', 'spew', 7, ['gush', 'bush', 'buss', 'suss', 'sues', 'shes', 'shew', 'spew']],
  // Monday
  ['team', 'crew', 4, ['team', 'tram', 'cram', 'craw', 'crew']],
  ['soap', 'suds', 4, ['soap', 'soup', 'sous', 'sods', 'suds']],
  ['rise', 'dawn', 5, ['rise', 'rase', 'rare', 'dare', 'darn', 'dawn']],
  ['tent', 'camp', 5, ['tent', 'cent', 'cant', 'cane', 'came', 'camp']],
  ['pout', 'sulk', 6, ['pout', 'bout', 'bolt', 'boll', 'bull', 'bulk', 'sulk']],
  ['over', 'time', 6, ['over', 'oyer', 'tyer', 'tyee', 'tyke', 'tike', 'time']],
  ['quay', 'dock', 7, ['quay', 'quad', 'quid', 'quit', 'duit', 'duct', 'duck', 'dock']],
  // Monday
  ['rate', 'cost', 4, ['rate', 'cate', 'case', 'cast', 'cost']],
  ['term', 'word', 4, ['term', 'derm', 'dorm', 'worm', 'word']],
  ['park', 'ride', 5, ['park', 'bark', 'bare', 'bade', 'bide', 'ride']],
  ['born', 'bred', 5, ['born', 'bort', 'boat', 'brat', 'brad', 'bred']],
  ['gold', 'rich', 6, ['gold', 'bold', 'bole', 'bile', 'bice', 'rice', 'rich']],
  ['road', 'path', 6, ['road', 'rood', 'pood', 'pooh', 'posh', 'pash', 'path']],
  ['wide', 'open', 7, ['wide', 'aide', 'aids', 'adds', 'odds', 'odes', 'opes', 'open']],
  // Monday
  ['tang', 'bite', 4, ['tang', 'bang', 'bane', 'bate', 'bite']],
  ['boss', 'lead', 4, ['boss', 'loss', 'less', 'leas', 'lead']],
  ['carp', 'fish', 5, ['carp', 'care', 'case', 'cash', 'fash', 'fish']],
  ['spur', 'goad', 5, ['spur', 'sour', 'lour', 'loud', 'load', 'goad']],
  ['thin', 'fine', 6, ['thin', 'chin', 'coin', 'conn', 'cone', 'cine', 'fine']],
  ['glue', 'tape', 6, ['glue', 'glee', 'ghee', 'thee', 'tyee', 'type', 'tape']],
  ['warm', 'snug', 7, ['warm', 'wart', 'wort', 'sort', 'soot', 'snot', 'snog', 'snug']],
  // Monday
  ['balm', 'heal', 4, ['balm', 'ball', 'bell', 'hell', 'heal']],
  ['sour', 'tart', 4, ['sour', 'tour', 'torr', 'tort', 'tart']],
  ['sept', 'clan', 5, ['sept', 'seat', 'feat', 'flat', 'flan', 'clan']],
  ['wail', 'moan', 5, ['wail', 'mail', 'main', 'mawn', 'mown', 'moan']],
  ['true', 'real', 6, ['true', 'tree', 'tres', 'tees', 'rees', 'reel', 'real']],
  ['gaze', 'view', 6, ['gaze', 'gage', 'gags', 'gaes', 'gies', 'vies', 'view']],
  ['knit', 'yarn', 7, ['knit', 'snit', 'skit', 'skid', 'said', 'sard', 'yard', 'yarn']],
  // Monday
  ['wise', 'sage', 4, ['wise', 'rise', 'rase', 'rage', 'sage']],
  ['room', 'ward', 4, ['room', 'rood', 'wood', 'word', 'ward']],
  ['road', 'lane', 5, ['road', 'load', 'lead', 'lend', 'land', 'lane']],
  ['chef', 'cook', 5, ['chef', 'chew', 'chow', 'chon', 'coon', 'cook']],
  ['cuff', 'bind', 5, ['cuff', 'curf', 'curd', 'burd', 'bird', 'bind']],
  ['life', 'soul', 6, ['life', 'lice', 'lick', 'lock', 'sock', 'souk', 'soul']],
  ['game', 'play', 7, ['game', 'gale', 'pale', 'pele', 'pelt', 'peat', 'plat', 'play']],
  // Monday
  ['trim', 'neat', 4, ['trim', 'tram', 'team', 'teat', 'neat']],
  ['spot', 'mote', 4, ['spot', 'soot', 'moot', 'mott', 'mote']],
  ['mist', 'haze', 5, ['mist', 'hist', 'hast', 'halt', 'hale', 'haze']],
  ['hive', 'nest', 5, ['hive', 'hove', 'hose', 'host', 'hest', 'nest']],
  ['rope', 'knot', 6, ['rope', 'roue', 'rout', 'root', 'soot', 'snot', 'knot']],
  ['tile', 'slab', 7, ['tile', 'bile', 'bole', 'bolt', 'boat', 'blat', 'blab', 'slab']],
  ['plus', 'more', 7, ['plus', 'flus', 'feus', 'feds', 'meds', 'mods', 'mode', 'more']],
  // Monday
  ['rock', 'band', 4, ['rock', 'bock', 'back', 'bank', 'band']],
  ['watt', 'volt', 4, ['watt', 'batt', 'bott', 'bolt', 'volt']],
  ['slog', 'toil', 5, ['slog', 'slot', 'soot', 'toot', 'toit', 'toil']],
  ['oohs', 'aahs', 5, ['oohs', 'oops', 'hops', 'haps', 'hahs', 'aahs']],
  ['drug', 'test', 6, ['drug', 'drag', 'brag', 'brat', 'beat', 'best', 'test']],
  ['awry', 'skew', 6, ['awry', 'away', 'sway', 'shay', 'shaw', 'shew', 'skew']],
  ['wade', 'swim', 7, ['wade', 'sade', 'sane', 'sand', 'said', 'skid', 'skim', 'swim']],
  // Monday
  ['lock', 'bolt', 4, ['lock', 'bock', 'book', 'boot', 'bolt']],
  ['frat', 'club', 4, ['frat', 'flat', 'flab', 'flub', 'club']],
  ['bath', 'bomb', 5, ['bath', 'bats', 'bots', 'boos', 'boob', 'bomb']],
  ['drip', 'swag', 5, ['drip', 'trip', 'trig', 'twig', 'swig', 'swag']],
  ['girl', 'lady', 6, ['girl', 'gill', 'gall', 'gals', 'gads', 'lads', 'lady']],
  ['free', 'form', 6, ['free', 'flee', 'flew', 'flaw', 'flam', 'foam', 'form']],
  ['typo', 'blip', 8, ['typo', 'topo', 'tops', 'cops', 'coos', 'coop', 'clop', 'clip', 'blip']],
  // Monday
  ['camp', 'site', 4, ['camp', 'came', 'cate', 'cite', 'site']],
  ['alum', 'grad', 4, ['alum', 'arum', 'grum', 'gram', 'grad']],
  ['bank', 'loan', 5, ['bank', 'bark', 'barn', 'born', 'lorn', 'loan']],
  ['gift', 'card', 5, ['gift', 'girt', 'dirt', 'dart', 'cart', 'card']],
  ['drag', 'lift', 6, ['drag', 'drat', 'doat', 'coat', 'coft', 'loft', 'lift']],
  ['owed', 'debt', 6, ['owed', 'ohed', 'shed', 'seed', 'deed', 'deet', 'debt']],
  ['spur', 'barb', 7, ['spur', 'sour', 'dour', 'dorr', 'dore', 'bore', 'bare', 'barb']],
  // Monday
  ['bike', 'rack', 4, ['bike', 'bake', 'rake', 'race', 'rack']],
  ['warm', 'milk', 4, ['warm', 'wark', 'mark', 'mirk', 'milk']],
  ['yell', 'roar', 5, ['yell', 'dell', 'deal', 'dear', 'rear', 'roar']],
  ['wire', 'mesh', 5, ['wire', 'mire', 'mere', 'mete', 'meth', 'mesh']],
  ['fray', 'duel', 6, ['fray', 'frat', 'feat', 'feal', 'deal', 'dual', 'duel']],
  ['nice', 'mean', 6, ['nice', 'mice', 'mics', 'mocs', 'moas', 'moan', 'mean']],
  ['arch', 'bend', 7, ['arch', 'arcs', 'ares', 'ayes', 'byes', 'bees', 'bens', 'bend']],
  // Monday
  ['barf', 'puke', 4, ['barf', 'bare', 'pare', 'pure', 'puke']],
  ['give', 'gift', 4, ['give', 'live', 'life', 'lift', 'gift']],
  ['glam', 'chic', 5, ['glam', 'clam', 'cham', 'chad', 'chid', 'chic']],
  ['dull', 'drab', 5, ['dull', 'doll', 'dolt', 'doat', 'drat', 'drab']],
  ['apex', 'peak', 6, ['apex', 'aped', 'sped', 'seed', 'peed', 'peek', 'peak']],
  ['hilt', 'grip', 6, ['hilt', 'gilt', 'gist', 'gast', 'gait', 'grit', 'grip']],
  ['flea', 'bite', 7, ['flea', 'fled', 'feed', 'fees', 'bees', 'bets', 'bits', 'bite']],
  // Monday
  ['fork', 'tine', 4, ['fork', 'fore', 'fire', 'fine', 'tine']],
  ['flax', 'seed', 4, ['flax', 'flex', 'fled', 'feed', 'seed']],
  ['weep', 'bawl', 5, ['weep', 'weel', 'well', 'bell', 'ball', 'bawl']],
  ['beta', 'fish', 5, ['beta', 'beth', 'bath', 'bash', 'fash', 'fish']],
  ['wear', 'garb', 6, ['wear', 'bear', 'beak', 'berk', 'bark', 'barb', 'garb']],
  ['aged', 'wine', 6, ['aged', 'ages', 'awes', 'awns', 'ains', 'wins', 'wine']],
  ['blur', 'fade', 7, ['blur', 'blub', 'flub', 'flus', 'feus', 'feds', 'fads', 'fade']],
  // Monday
  ['hark', 'hear', 4, ['hark', 'hard', 'herd', 'head', 'hear']],
  ['seed', 'tree', 4, ['seed', 'sees', 'tees', 'tres', 'tree']],
  ['pair', 'bond', 5, ['pair', 'lair', 'laid', 'land', 'band', 'bond']],
  ['goop', 'gunk', 5, ['goop', 'hoop', 'hook', 'honk', 'hunk', 'gunk']],
  ['dupe', 'fool', 6, ['dupe', 'dope', 'cope', 'cops', 'coos', 'cool', 'fool']],
  ['daub', 'blot', 6, ['daub', 'drub', 'drab', 'drat', 'brat', 'blat', 'blot']],
  ['grid', 'zone', 7, ['grid', 'grad', 'brad', 'bead', 'bend', 'bene', 'bone', 'zone']],
  // Monday
  ['bold', 'dare', 4, ['bold', 'bald', 'bale', 'bare', 'dare']],
  ['snag', 'tear', 4, ['snag', 'scag', 'scar', 'sear', 'tear']],
  ['fast', 'zoom', 5, ['fast', 'last', 'lost', 'loot', 'loom', 'zoom']],
  ['room', 'mate', 5, ['room', 'root', 'moot', 'mott', 'matt', 'mate']],
  ['shop', 'mall', 6, ['shop', 'chop', 'coop', 'cool', 'mool', 'moll', 'mall']],
  ['wild', 'free', 6, ['wild', 'gild', 'gied', 'gled', 'fled', 'flee', 'free']],
  ['grid', 'cell', 7, ['grid', 'grad', 'brad', 'bead', 'beat', 'belt', 'bell', 'cell']],
  // Monday
  ['bore', 'dull', 4, ['bore', 'bole', 'boll', 'bull', 'dull']],
  ['trick', 'treat', 7, ['trick', 'wrick', 'wreck', 'wreak', 'break', 'bread', 'tread', 'treat'], '🎃', "Happy Halloween", 'orange'], // 10/31/2023
  ['lock', 'seal', 5, ['lock', 'pock', 'peck', 'peak', 'peal', 'seal']],
  ['easy', 'calm', 5, ['easy', 'ease', 'base', 'bale', 'balm', 'calm']],
  ['weld', 'fuse', 6, ['weld', 'meld', 'mell', 'mull', 'mule', 'muse', 'fuse']],
  ['scar', 'mark', 6, ['scar', 'sear', 'bear', 'beak', 'berk', 'bark', 'mark']],
  ['knee', 'bend', 8, ['knee', 'knew', 'anew', 'anes', 'ayes', 'byes', 'bees', 'bens', 'bend']],
  // Monday
  ['sick', 'well', 4, ['sick', 'silk', 'sill', 'sell', 'well']],
  ['jump', 'leap', 4, ['jump', 'hump', 'hemp', 'heap', 'leap']],
  ['pact', 'deal', 5, ['pact', 'pack', 'peck', 'peak', 'peal', 'deal']],
  ['mold', 'clay', 5, ['mold', 'gold', 'goad', 'glad', 'clad', 'clay']],
  ['bury', 'tomb', 6, ['bury', 'burn', 'born', 'boon', 'boob', 'bomb', 'tomb']],
  ['arid', 'lush', 7, ['arid', 'grid', 'grit', 'gait', 'gast', 'last', 'lust', 'lush']],
  ['fresh', 'start', 8, ['fresh', 'flesh', 'flash', 'flask', 'flack', 'slack', 'stack', 'stark', 'start']],
  // Monday
  ['warp', 'bend', 4, ['warp', 'ward', 'wand', 'band', 'bend']],
  ['land', 'soil', 4, ['land', 'laid', 'said', 'sail', 'soil']],
  ['moon', 'tide', 5, ['moon', 'morn', 'more', 'mire', 'tire', 'tide']],
  ['neat', 'tidy', 5, ['neat', 'teat', 'tent', 'tint', 'tiny', 'tidy']],
  ['year', 'time', 6, ['year', 'tear', 'teal', 'tell', 'till', 'tile', 'time']],
  ['pick', 'grab', 6, ['pick', 'peck', 'peak', 'peat', 'prat', 'grat', 'grab']],
  ['sleep', 'dream', 6, ['sleep', 'bleep', 'bleed', 'breed', 'bread', 'dread', 'dream']],
  // Monday
  ['menu', 'food', 4, ['menu', 'mend', 'fend', 'fond', 'food']],
  ['riot', 'coup', 4, ['riot', 'root', 'coot', 'coop', 'coup']],
  ['warm', 'heat', 5, ['warm', 'harm', 'hard', 'herd', 'head', 'heat']],
  ['moon', 'walk', 5, ['moon', 'mool', 'moll', 'mall', 'wall', 'walk']],
  ['sway', 'tilt', 6, ['sway', 'swat', 'seat', 'sent', 'tent', 'tint', 'tilt']],
  ['atom', 'bomb', 7, ['atom', 'atop', 'stop', 'slop', 'slob', 'blob', 'boob', 'bomb']],
  ['brain', 'heart', 7, ['brain', 'braid', 'brand', 'brans', 'beans', 'bears', 'hears', 'heart']],
  // Monday
  ['zest', 'peel', 4, ['zest', 'fest', 'feet', 'feel', 'peel']],
  ['jail', 'cell', 4, ['jail', 'bail', 'ball', 'bell', 'cell']],
  ['heat', 'boil', 5, ['heat', 'beat', 'belt', 'bell', 'boll', 'boil']],
  ['grab', 'snag', 5, ['grab', 'gram', 'glam', 'slam', 'slag', 'snag']],
  ['mate', 'beau', 6, ['mate', 'bate', 'bane', 'band', 'bend', 'bead', 'beau']],
  ['chip', 'byte', 7, ['chip', 'chin', 'cain', 'carn', 'barn', 'bare', 'bate', 'byte']],
  ['brown', 'acorn', 8, ['brown', 'blown', 'blows', 'slows', 'shows', 'shown', 'shorn', 'scorn', 'acorn']],
  // Monday
  ['mild', 'calm', 4, ['mild', 'mill', 'mall', 'call', 'calm']],
  ['beat', 'flog', 4, ['beat', 'feat', 'flat', 'flag', 'flog']],
  ['pour', 'fill', 5, ['pour', 'poor', 'pool', 'poll', 'pill', 'fill']],
  ['word', 'text', 5, ['word', 'wort', 'wont', 'went', 'tent', 'text']],
  ['slam', 'bang', 6, ['slam', 'blam', 'beam', 'bead', 'bend', 'band', 'bang']],
  ['stew', 'boil', 7, ['stew', 'slew', 'sled', 'slid', 'said', 'sail', 'bail', 'boil']],
  ['hover', 'board', 8, ['hover', 'homer', 'homes', 'holes', 'boles', 'bolts', 'boats', 'boars', 'board']],
  // Monday
  ['malt', 'beer', 4, ['malt', 'melt', 'belt', 'beet', 'beer']],
  ['kiln', 'bake', 5, ['kiln', 'kill', 'bill', 'ball', 'bale', 'bake']],
  ['home', 'nest', 4, ['home', 'hose', 'host', 'hest', 'nest']],
  ['plow', 'till', 6, ['plow', 'plop', 'poop', 'pool', 'poll', 'pill', 'till']],
  ['stem', 'root', 4, ['stem', 'stet', 'stot', 'soot', 'root']],
  ['juke', 'spin', 7, ['juke', 'jake', 'jane', 'sane', 'sand', 'said', 'sain', 'spin']],
  ['paint', 'brush', 6, ['paint', 'print', 'prink', 'brink', 'brisk', 'brusk', 'brush']],
  // Monday
  ['dash', 'race', 3, ['dash', 'rash', 'rase', 'race']],
  ['wool', 'yarn', 5, ['wool', 'wood', 'word', 'ward', 'yard', 'yarn']],
  ['pike', 'fish', 5, ['pike', 'pine', 'wine', 'wise', 'wish', 'fish']],
  ['loud', 'yell', 5, ['loud', 'laud', 'yaud', 'yald', 'yeld', 'yell']],
  ['tone', 'deaf', 5, ['tone', 'done', 'dene', 'dele', 'delf', 'deaf']],
  ['five', 'star', 7, ['five', 'file', 'fill', 'fell', 'sell', 'seal', 'sear', 'star']],
  ['small', 'giant', 9, ['small', 'stall', 'stalk', 'stank', 'slank', 'blank', 'brank', 'brant', 'grant', 'giant']],
  // Monday
  ['star', 'tree', 6, ['star', 'sear', 'seas', 'sees', 'tees', 'tres', 'tree']],
  ['fire', 'logs', 4, ['fire', 'firs', 'figs', 'fogs', 'logs']],
  ['slay', 'bell', 5, ['slay', 'slam', 'seam', 'seal', 'sell', 'bell']],
  ['give', 'back', 5, ['give', 'gave', 'gale', 'bale', 'balk', 'back']],
  ['snow', 'ball', 6, ['snow', 'snot', 'soot', 'boot', 'bolt', 'boll', 'ball']],
  ['cold', 'snap', 6, ['cold', 'cols', 'sols', 'sous', 'soup', 'soap', 'snap']],
  ['crazy', 'party', 9, ['crazy', 'craze', 'crate', 'prate', 'plate', 'platy', 'peaty', 'pesty', 'pasty', 'party']],
  // Monday
  ['sore', 'head', 4, ['sore', 'sere', 'here', 'herd', 'head']],
  ['read', 'book', 4, ['read', 'road', 'rood', 'rook', 'book']],
  ['herd', 'goat', 5, ['herd', 'head', 'bead', 'beat', 'boat', 'goat']],
  ['coin', 'cash', 5, ['coin', 'corn', 'core', 'care', 'case', 'cash']],
  ['walk', 'trot', 6, ['walk', 'talk', 'tall', 'toll', 'tool', 'toot', 'trot']],
  ['gang', 'crew', 7, ['gang', 'bang', 'bans', 'baas', 'bras', 'braw', 'brew', 'crew']],
  ['tooth', 'fairy', 7, ['tooth', 'toots', 'foots', 'fools', 'foils', 'fails', 'fairs', 'fairy']],
  // Monday
  ['tack', 'nail', 4, ['tack', 'talk', 'tall', 'tail', 'nail']],
  ['life', 'born', 4, ['life', 'lire', 'lore', 'bore', 'born']],
  ['safe', 'lock', 5, ['safe', 'same', 'lame', 'lace', 'lack', 'lock']],
  ['keto', 'diet', 5, ['keto', 'keno', 'kent', 'dent', 'deet', 'diet']],
  ['debt', 'loan', 6, ['debt', 'dent', 'lent', 'lend', 'lead', 'load', 'loan']],
  ['cash', 'flow', 7, ['cash', 'cast', 'fast', 'fest', 'feat', 'flat', 'flaw', 'flow']],
  ['mitts', 'scarf', 7, ['mitts', 'bitts', 'botts', 'boats', 'boars', 'soars', 'scars', 'scarf']],
  // Monday
  ['fret', 'fuss', 4, ['fret', 'feet', 'fees', 'fess', 'fuss']],
  ['tape', 'bind', 5, ['tape', 'cape', 'cane', 'bane', 'band', 'bind']],
  ['bail', 'free', 5, ['bail', 'bait', 'brit', 'brie', 'bree', 'free']],
  ['sour', 'lime', 6, ['sour', 'sous', 'sons', 'sins', 'sine', 'line', 'lime']],
  ['talk', 'slur', 6, ['talk', 'tack', 'sack', 'sock', 'souk', 'sour', 'slur']],
  ['snow', 'bird', 7, ['snow', 'show', 'shew', 'shed', 'sued', 'surd', 'burd', 'bird']],
  ['perch', 'roost', 7, ['perch', 'peach', 'poach', 'coach', 'coact', 'coast', 'roast', 'roost']],
  // Monday
  ['core', 'root', 4, ['core', 'cork', 'cook', 'rook', 'root']],
  ['bark', 'woof', 5, ['bark', 'bard', 'ward', 'word', 'wood', 'woof']],
  ['bump', 'dent', 5, ['bump', 'bums', 'buns', 'bunt', 'bent', 'dent']],
  ['coil', 'wind', 6, ['coil', 'boil', 'boll', 'bill', 'will', 'wild', 'wind']],
  ['skin', 'pore', 6, ['skin', 'shin', 'chin', 'coin', 'corn', 'core', 'pore']],
  ['dive', 'swim', 8, ['dive', 'dine', 'sine', 'sane', 'sand', 'said', 'slid', 'slim', 'swim']],
  ['floss', 'teeth', 8, ['floss', 'flogs', 'flags', 'flats', 'feats', 'teats', 'tents', 'tenth', 'teeth']],
  // Monday
  ['baby', 'talk', 4, ['baby', 'babe', 'bale', 'tale', 'talk']],
  ['fork', 'lift', 5, ['fork', 'fore', 'fire', 'fife', 'life', 'lift']],
  ['kite', 'sail', 5, ['kite', 'site', 'sate', 'sale', 'sall', 'sail']],
  ['star', 'fish', 6, ['star', 'sear', 'fear', 'feat', 'fest', 'fist', 'fish']],
  ['butt', 'dial', 6, ['butt', 'bust', 'dust', 'duet', 'duel', 'dual', 'dial']],
  ['sand', 'crab', 7, ['sand', 'said', 'slid', 'slip', 'clip', 'clap', 'crap', 'crab']],
  ['cheek', 'blush', 8, ['cheek', 'check', 'chick', 'crick', 'brick', 'brisk', 'brusk', 'brush', 'blush']],
  // Monday
  ['home', 'cozy', 4, ['home', 'come', 'cope', 'copy', 'cozy']],
  ['seed', 'bulb', 5, ['seed', 'seel', 'sell', 'bell', 'bull', 'bulb']],
  ['pack', 'move', 5, ['pack', 'mack', 'mace', 'made', 'mode', 'move']],
  ['cafe', 'bean', 6, ['cafe', 'cane', 'bane', 'band', 'bend', 'bead', 'bean']],
  ['food', 'baby', 6, ['food', 'fold', 'bold', 'bald', 'bale', 'babe', 'baby']],
  ['skip', 'jump', 7, ['skip', 'slip', 'slop', 'plop', 'poop', 'pomp', 'pump', 'jump']],
  ['waste', 'trash', 8, ['waste', 'baste', 'basts', 'baits', 'brits', 'brats', 'brass', 'brash', 'trash']],
  // Monday
  ['bank', 'note', 4, ['bank', 'bane', 'bone', 'none', 'note']],
  ['stew', 'soup', 5, ['stew', 'slew', 'slaw', 'slap', 'soap', 'soup']],
  ['candy', 'heart', 7, ['candy', 'bandy', 'bands', 'bends', 'beads', 'bears', 'hears', 'heart'], '❤️', "Valentine's Day", 'red'],
  ['rest', 'room', 5, ['rest', 'lest', 'lost', 'loot', 'loom', 'room']],
  ['prop', 'item', 6, ['prop', 'plop', 'slop', 'stop', 'step', 'stem', 'item']],
  ['coat', 'arms', 7, ['coat', 'boat', 'boas', 'bias', 'bids', 'aids', 'aims', 'arms']],
  ['parka', 'scarf', 7, ['parka', 'parks', 'perks', 'peaks', 'pears', 'sears', 'scars', 'scarf']],
  // Monday
  ['pack', 'mule', 4, ['pack', 'mack', 'mace', 'male', 'mule']],
  ['feed', 'back', 5, ['feed', 'fend', 'bend', 'band', 'bank', 'back']],
  ['deal', 'cope', 5, ['deal', 'dell', 'doll', 'dole', 'cole', 'cope']],
  ['slob', 'mess', 6, ['slob', 'slot', 'soot', 'moot', 'most', 'moss', 'mess']],
  ['skate', 'board', 6, ['skate', 'state', 'stats', 'stars', 'soars', 'boars', 'board']],
  ['grid', 'line', 7, ['grid', 'grit', 'writ', 'wait', 'want', 'wane', 'wine', 'line']],
  ['grain', 'wheat', 9, ['grain', 'groin', 'groan', 'groat', 'gloat', 'bloat', 'bleat', 'cleat', 'cheat', 'wheat']],
  // Monday
  ['stop', 'play', 4, ['stop', 'slop', 'slap', 'slay', 'play']],
  ['bugs', 'life', 5, ['bugs', 'buts', 'bits', 'bite', 'lite', 'life']],
  ['ship', 'crew', 5, ['ship', 'shop', 'show', 'chow', 'crow', 'crew']],
  ['hair', 'root', 6, ['hair', 'fair', 'fail', 'foil', 'fool', 'foot', 'root']],
  ['plant', 'bloom', 6, ['plant', 'plank', 'blank', 'bland', 'blond', 'blood', 'bloom']],
  ['blog', 'page', 7, ['blog', 'biog', 'bios', 'bigs', 'bags', 'gags', 'gage', 'page']],
  ['brain', 'storm', 8, ['brain', 'blain', 'slain', 'stain', 'stein', 'stern', 'stere', 'store', 'storm']],
  // Monday
  ['mast', 'sail', 4, ['mast', 'malt', 'mall', 'mail', 'sail']],
  ['tiny', 'vast', 5, ['tiny', 'tint', 'lint', 'list', 'last', 'vast']],
  ['star', 'dust', 5, ['star', 'stat', 'stet', 'suet', 'duet', 'dust']],
  ['fool', 'sage', 6, ['fool', 'food', 'fold', 'sold', 'sole', 'sale', 'sage']],
  ['story', 'board', 6, ['story', 'store', 'stare', 'stars', 'soars', 'boars', 'board']],
  ['ties', 'knot', 7, ['ties', 'toes', 'tors', 'tort', 'sort', 'soot', 'snot', 'knot']],
  ['party', 'trick', 8, ['party', 'parts', 'tarts', 'tarns', 'tains', 'thins', 'think', 'thick', 'trick']],
  // Monday
  ['silk', 'felt', 4, ['silk', 'sill', 'sell', 'fell', 'felt']],
  ['dull', 'keen', 5, ['dull', 'full', 'fell', 'feel', 'keel', 'keen']],
  ['head', 'boss', 5, ['head', 'heat', 'beat', 'boat', 'boas', 'boss']],
  ['star', 'film', 6, ['star', 'sear', 'seal', 'sell', 'fell', 'fill', 'film']],
  ['wolf', 'fang', 6, ['wolf', 'wold', 'bold', 'bald', 'band', 'bang', 'fang']],
  ['crew', 'neck', 7, ['crew', 'brew', 'bred', 'brad', 'bead', 'beak', 'beck', 'neck']],
  ['stake', 'steak', 7, ['stake', 'shake', 'shale', 'shall', 'shell', 'sheal', 'steal', 'steak']],
  // Monday
  ['milk', 'calf', 4, ['milk', 'mill', 'mall', 'call', 'calf']],
  ['dupe', 'fake', 4, ['dupe', 'duke', 'juke', 'jake', 'fake']],
  ['slim', 'lean', 5, ['slim', 'slam', 'seam', 'beam', 'bean', 'lean']],
  ['wear', 'ware', 5, ['wear', 'weal', 'well', 'wall', 'wale', 'ware']],
  ['knack', 'skill', 6, ['knack', 'snack', 'stack', 'stalk', 'stall', 'still', 'skill']],
  ['vile', 'foul', 6, ['vile', 'bile', 'bill', 'boll', 'boil', 'foil', 'foul']],
  ['brick', 'steel', 8, ['brick', 'crick', 'chick', 'check', 'cheek', 'cheer', 'sheer', 'steer', 'steel']],
  // Monday
  ['meal', 'prep', 4, ['meal', 'peal', 'peel', 'peep', 'prep']],
  ['bulk', 'mass', 4, ['bulk', 'balk', 'bask', 'mask', 'mass']],
  ['rock', 'slab', 5, ['rock', 'sock', 'soak', 'soap', 'slap', 'slab']],
  ['grass', 'roots', 5, ['grass', 'brass', 'brats', 'boats', 'boots', 'roots']],
  ['plot', 'line', 6, ['plot', 'plat', 'peat', 'pent', 'pint', 'pine', 'line']],
  ['duty', 'free', 7, ['duty', 'doty', 'dots', 'does', 'toes', 'tres', 'tree', 'free']],
  ['fresh', 'stale', 8, ['fresh', 'flesh', 'flash', 'flask', 'flack', 'slack', 'stack', 'stalk', 'stale']],
  // Monday
  ['king', 'rule', 4, ['king', 'ring', 'rung', 'rune', 'rule']],
  ['honey', 'combs', 4, ['honey', 'homey', 'homes', 'comes', 'combs']],
  ['snap', 'back', 5, ['snap', 'soap', 'soak', 'sock', 'sack', 'back']],
  ['bear', 'cave', 6, ['bear', 'bead', 'bend', 'band', 'bane', 'cane', 'cave']],
  ['binge', 'watch', 6, ['binge', 'singe', 'since', 'wince', 'winch', 'witch', 'watch']],
  ['inch', 'yard', 8, ['inch', 'itch', 'etch', 'each', 'bach', 'back', 'bark', 'bard', 'yard']],
  ['quiet', 'place', 8, ['quiet', 'quilt', 'guilt', 'guile', 'guide', 'glide', 'glade', 'glace', 'place']],
  // Monday
  ['grass', 'blade', 4, ['grass', 'grads', 'grade', 'glade', 'blade']],
  ['east', 'side', 5, ['east', 'bast', 'base', 'bade', 'bide', 'side']],
  ['dune', 'hill', 5, ['dune', 'dine', 'dint', 'hint', 'hilt', 'hill']],
  ['foil', 'wrap', 6, ['foil', 'coil', 'cool', 'coop', 'crop', 'crap', 'wrap']],
  ['solar', 'panel', 6, ['solar', 'polar', 'poler', 'paler', 'paled', 'paned', 'panel']],
  ['take', 'flak', 7, ['take', 'fake', 'fare', 'farm', 'form', 'foam', 'flam', 'flak']],
  ['sharp', 'blunt', 8, ['sharp', 'scarp', 'scars', 'soars', 'sours', 'slurs', 'blurs', 'blurt', 'blunt']],
  // Monday
  ['swap', 'meet', 4, ['swap', 'swat', 'seat', 'meat', 'meet']],
  ['grub', 'food', 5, ['grub', 'grab', 'grad', 'goad', 'good', 'food']],
  ['moth', 'lamp', 5, ['moth', 'math', 'mate', 'late', 'lame', 'lamp']],
  ['drip', 'leak', 6, ['drip', 'trip', 'trap', 'tram', 'team', 'teak', 'leak']],
  ['wheat', 'bread', 6, ['wheat', 'cheat', 'cleat', 'bleat', 'bleak', 'break', 'bread']],
  ['ergo', 'thus', 6, ['ergo', 'ergs', 'errs', 'ears', 'tars', 'taus', 'thus']],
  ['broom', 'stick', 7, ['broom', 'brook', 'brock', 'block', 'black', 'slack', 'stack', 'stick']],
  // Monday
  ['note', 'memo', 4, ['note', 'mote', 'mete', 'meme', 'memo']],
  ['pact', 'bond', 5, ['pact', 'pack', 'back', 'bank', 'band', 'bond']],
  ['inner', 'outer', 5, ['inner', 'inter', 'enter', 'eater', 'oater', 'outer']],
  ['slug', 'worm', 6, ['slug', 'slog', 'slot', 'soot', 'sort', 'wort', 'worm']],
  ['chop', 'dice', 6, ['chop', 'coop', 'comp', 'come', 'dome', 'dime', 'dice']],
  ['black', 'board', 7, ['black', 'slack', 'stack', 'stark', 'stars', 'soars', 'boars', 'board']],
  ['lucky', 'charm', 9, ['lucky', 'lucks', 'locks', 'cocks', 'cooks', 'coops', 'chops', 'chaps', 'chars', 'charm']],
  // Monday
  ['myth', 'lore', 4, ['myth', 'moth', 'mote', 'more', 'lore']],
  ['base', 'root', 5, ['base', 'bass', 'boss', 'boos', 'boot', 'root']],
  ['sand', 'grit', 5, ['sand', 'wand', 'want', 'wait', 'gait', 'grit']],
  ['gray', 'dull', 6, ['gray', 'dray', 'drat', 'doat', 'dolt', 'doll', 'dull']],
  ['stock', 'price', 6, ['stock', 'stick', 'slick', 'click', 'crick', 'prick', 'price']],
  ['baby', 'crib', 7, ['baby', 'babe', 'base', 'bast', 'bait', 'brit', 'crit', 'crib']],
  ['frown', 'smile', 9, ['frown', 'flown', 'flows', 'slows', 'slots', 'spots', 'spits', 'spite', 'smite', 'smile']],
  // Monday
  ['earn', 'make', 4, ['earn', 'barn', 'bare', 'bake', 'make']],
  ['glass', 'blown', 4, ['glass', 'gloss', 'glows', 'blows', 'blown']],
  ['raid', 'loot', 5, ['raid', 'laid', 'lain', 'loin', 'loon', 'loot']],
  ['pave', 'road', 6, ['pave', 'pare', 'pore', 'lore', 'lord', 'load', 'road']],
  ['shave', 'beard', 6, ['shave', 'shame', 'shams', 'seams', 'beams', 'bears', 'beard']],
  ['maul', 'claw', 7, ['maul', 'mail', 'maid', 'caid', 'chid', 'chad', 'chaw', 'claw']],
  ['trace', 'scent', 8, ['trace', 'grace', 'glace', 'place', 'plane', 'plant', 'slant', 'scant', 'scent']],
  // Monday
  ['defy', 'dare', 4, ['defy', 'deft', 'daft', 'dart', 'dare']],
  ['text', 'book', 5, ['text', 'teat', 'beat', 'boat', 'boot', 'book']],
  ['disk', 'save', 5, ['disk', 'dink', 'dank', 'sank', 'sane', 'save']],
  ['cram', 'pack', 6, ['cram', 'tram', 'team', 'teak', 'peak', 'peck', 'pack']],
  ['wing', 'soar', 6, ['wing', 'sing', 'sink', 'sick', 'sock', 'soak', 'soar']],
  ['bring', 'along', 7, ['bring', 'brink', 'blink', 'clink', 'cline', 'aline', 'alone', 'along']],
  ['conch', 'shell', 9, ['conch', 'coach', 'clach', 'clack', 'slack', 'stack', 'stalk', 'stall', 'shall', 'shell']],
  // Monday
  ['cage', 'coop', 4, ['cage', 'came', 'camp', 'comp', 'coop']],
  ['cash', 'bill', 5, ['cash', 'bash', 'base', 'bale', 'ball', 'bill']],
  ['fish', 'tank', 5, ['fish', 'dish', 'disk', 'dink', 'dank', 'tank']],
  ['work', 'flow', 6, ['work', 'wort', 'sort', 'soot', 'slot', 'slow', 'flow']],
  ['smart', 'phone', 6, ['smart', 'start', 'stare', 'share', 'shore', 'shone', 'phone']],
  ['dense', 'thick', 7, ['dense', 'sense', 'seise', 'seine', 'shine', 'thine', 'think', 'thick']],
  ['thumb', 'print', 9, ['thumb', 'thump', 'trump', 'tramp', 'trams', 'trans', 'trank', 'prank', 'prink', 'print']],
  // Monday
  ['snap', 'chat', 4, ['snap', 'slap', 'clap', 'chap', 'chat']],
  ['vast', 'deep', 5, ['vast', 'bast', 'best', 'beet', 'beep', 'deep']],
  ['grip', 'fist', 5, ['grip', 'grit', 'frit', 'frat', 'fiat', 'fist']],
  ['draw', 'pull', 6, ['draw', 'drat', 'doat', 'dolt', 'doll', 'dull', 'pull']],
  ['chaos', 'peace', 6, ['chaos', 'chaps', 'chape', 'chase', 'cease', 'pease', 'peace']],
  ['chess', 'board', 7, ['chess', 'chews', 'chaws', 'chats', 'coats', 'boats', 'boars', 'board']],
  ['trend', 'style', 9, ['trend', 'tread', 'triad', 'trial', 'trill', 'thill', 'shill', 'still', 'stile', 'style']],
  // Monday
  ['gold', 'mine', 4, ['gold', 'gild', 'mild', 'mile', 'mine']],
  ['trim', 'clip', 5, ['trim', 'tram', 'cram', 'clam', 'clap', 'clip']],
  ['fond', 'keen', 5, ['fond', 'fend', 'feed', 'feel', 'keel', 'keen']],
  ['fire', 'clay', 6, ['fire', 'firm', 'form', 'foam', 'flam', 'clam', 'clay']],
  ['north', 'south', 6, ['north', 'forth', 'forts', 'foots', 'soots', 'sooth', 'south']],
  ['sunny', 'beach', 7, ['sunny', 'punny', 'penny', 'penne', 'pence', 'peace', 'peach', 'beach']],
  ['model', 'plane', 8, ['model', 'modes', 'mores', 'morns', 'moans', 'means', 'peans', 'plans', 'plane']],
  // Monday
  ['post', 'card', 4, ['post', 'cost', 'cast', 'cart', 'card']],
  ['keep', 'oust', 5, ['keep', 'beep', 'beet', 'best', 'bust', 'oust']],
  ['word', 'play', 5, ['word', 'wood', 'pood', 'plod', 'ploy', 'play']],
  ['cash', 'fund', 6, ['cash', 'bash', 'base', 'bane', 'band', 'bund', 'fund']],
  ['flint', 'stone', 6, ['flint', 'fling', 'flong', 'along', 'alone', 'atone', 'stone']],
  ['soap', 'wash', 7, ['soap', 'soak', 'sock', 'sack', 'back', 'bach', 'bash', 'wash']],
  ['bread', 'crumb', 9, ['bread', 'breed', 'brees', 'braes', 'brags', 'crags', 'crams', 'cramp', 'crump', 'crumb']],
  // Monday
  ['wild', 'card', 4, ['wild', 'wind', 'wand', 'ward', 'card']],
  ['font', 'type', 5, ['font', 'fons', 'fops', 'tops', 'tope', 'type']],
  ['fury', 'rage', 5, ['fury', 'furl', 'farl', 'fare', 'rare', 'rage']],
  ['hard', 'stop', 6, ['hard', 'herd', 'heed', 'seed', 'seep', 'step', 'stop']],
  ['drop', 'rise', 7, ['drop', 'prop', 'poop', 'pooh', 'posh', 'pose', 'rose', 'rise']],
  ['sword', 'blade', 7, ['sword', 'sward', 'sware', 'spare', 'spate', 'slate', 'blate', 'blade']],
  ['creek', 'river', 8, ['creek', 'cheek', 'cheer', 'sheer', 'shyer', 'sayer', 'saver', 'raver', 'river']],
  // Monday
  ['cast', 'mold', 4, ['cast', 'cost', 'colt', 'cold', 'mold']],
  ['prod', 'spur', 5, ['prod', 'pood', 'poor', 'pour', 'sour', 'spur']],
  ['jelly', 'beans', 5, ['jelly', 'belly', 'bells', 'belts', 'beats', 'beans']],
  ['calm', 'clam', 6, ['calm', 'balm', 'barm', 'berm', 'beam', 'blam', 'clam']],
  ['flash', 'flood', 7, ['flash', 'flask', 'flank', 'blank', 'bland', 'blond', 'blood', 'flood']],
  ['knee', 'jerk', 9, ['knee', 'knew', 'anew', 'anes', 'ayes', 'pyes', 'pees', 'peek', 'perk', 'jerk']],
  ['green', 'thumb', 9, ['green', 'grees', 'greys', 'grays', 'grams', 'gramp', 'grump', 'trump', 'thump', 'thumb']],
  // Monday
  ['mare', 'colt', 4, ['mare', 'care', 'core', 'cole', 'colt']],
  ['creek', 'brook', 4, ['creek', 'creak', 'croak', 'crook', 'brook']],
  ['bird', 'poop', 5, ['bird', 'bind', 'bond', 'pond', 'pood', 'poop']],
  ['flip', 'toss', 6, ['flip', 'clip', 'clop', 'coop', 'coos', 'coss', 'toss']],
  ['black', 'smith', 7, ['black', 'slack', 'slick', 'slice', 'spice', 'spite', 'smite', 'smith']],
  ['shame', 'pride', 7, ['shame', 'shams', 'chams', 'crams', 'prams', 'prims', 'prime', 'pride']],
  ['paper', 'crane', 9, ['paper', 'paler', 'pales', 'peles', 'pelts', 'peats', 'prats', 'prate', 'crate', 'crane']],
  // Monday
  ['deed', 'done', 4, ['deed', 'dees', 'does', 'dons', 'done']],
  ['scaly', 'snake', 4, ['scaly', 'scale', 'scare', 'snare', 'snake']],
  ['city', 'hall', 5, ['city', 'cite', 'cate', 'hate', 'hale', 'hall']],
  ['stag', 'buck', 6, ['stag', 'stat', 'seat', 'beat', 'beak', 'beck', 'buck']],
  ['sweet', 'dream', 6, ['sweet', 'tweet', 'tweed', 'treed', 'dreed', 'dread', 'dream']],
  ['wolf', 'lamb', 7, ['wolf', 'golf', 'gulf', 'guls', 'gals', 'gams', 'gamb', 'lamb']],
  ['briny', 'spray', 12, ['briny', 'brink', 'blink', 'blind', 'blend', 'bleed', 'bleep', 'sleep', 'steep', 'strep', 'strap', 'stray', 'spray']],
  // Monday
  ['tint', 'dyed', 4, ['tint', 'dint', 'diet', 'died', 'dyed']],
  ['lord', 'king', 5, ['lord', 'ford', 'fond', 'find', 'kind', 'king']],
  ['bait', 'trap', 5, ['bait', 'brit', 'grit', 'grip', 'trip', 'trap']],
  ['page', 'leaf', 6, ['page', 'pane', 'lane', 'land', 'lend', 'lead', 'leaf']],
  ['fella', 'matey', 6, ['fella', 'fells', 'falls', 'malls', 'males', 'mates', 'matey']],
  ['creep', 'crawl', 7, ['creep', 'creed', 'breed', 'brees', 'braes', 'braws', 'brawl', 'crawl']],
  ['space', 'craft', 10, ['space', 'spare', 'spark', 'spank', 'slank', 'blank', 'brank', 'brant', 'grant', 'graft', 'craft']],
  // Monday
  ['make', 'form', 4, ['make', 'fake', 'fare', 'farm', 'form']],
  ['crack', 'break', 4, ['crack', 'crock', 'croak', 'creak', 'break']],
  ['town', 'folk', 5, ['town', 'torn', 'corn', 'cork', 'fork', 'folk']],
  ['writ', 'deed', 5, ['writ', 'frit', 'fret', 'feet', 'deet', 'deed']],
  ['brick', 'stone', 7, ['brick', 'trick', 'thick', 'think', 'thine', 'shine', 'shone', 'stone']],
  ['strum', 'chord', 7, ['strum', 'strut', 'stout', 'shout', 'short', 'shore', 'chore', 'chord']],
  ['fiber', 'dairy', 8, ['fiber', 'filer', 'files', 'fills', 'dills', 'dilly', 'dally', 'daily', 'dairy']],
  // Monday
  ['nail', 'file', 4, ['nail', 'fail', 'fall', 'fill', 'file']],
  ['lure', 'hook', 5, ['lure', 'cure', 'core', 'cork', 'cook', 'hook']],
  ['lamp', 'bulb', 5, ['lamp', 'lump', 'bump', 'burp', 'burb', 'bulb']],
  ['axel', 'spin', 6, ['axel', 'axed', 'aped', 'sped', 'spec', 'spic', 'spin']],
  ['baker', 'dozen', 6, ['baker', 'baked', 'bared', 'dared', 'dazed', 'dozed', 'dozen']],
  ['grape', 'vines', 8, ['grape', 'grade', 'grads', 'brads', 'beads', 'bends', 'benes', 'bines', 'vines']],
  ['tenth', 'floor', 11, ['tenth', 'tents', 'bents', 'beats', 'beans', 'brans', 'brand', 'bland', 'blond', 'blood', 'flood', 'floor']],
  // Monday
  ['time', 'card', 4, ['time', 'tame', 'came', 'care', 'card']],
  ['burn', 'wick', 5, ['burn', 'barn', 'bark', 'back', 'wack', 'wick']],
  ['pawn', 'rook', 5, ['pawn', 'sawn', 'sown', 'soon', 'sook', 'rook']],
  ['snow', 'peas', 6, ['snow', 'scow', 'scot', 'scat', 'seat', 'peat', 'peas']],
  ['sheep', 'flock', 6, ['sheep', 'cheep', 'cheek', 'check', 'chock', 'clock', 'flock']],
  ['horse', 'rider', 8, ['horse', 'house', 'rouse', 'route', 'routs', 'roues', 'rodes', 'rides', 'rider']],
  ['windy', 'storm', 10, ['windy', 'winds', 'binds', 'bends', 'beads', 'bears', 'sears', 'stars', 'stare', 'store', 'storm']],
  // Monday
  ['fork', 'road', 4, ['fork', 'ford', 'lord', 'load', 'road']],
  ['door', 'step', 5, ['door', 'doer', 'deer', 'deep', 'seep', 'step']],
  ['smirk', 'smile', 5, ['smirk', 'shirk', 'shire', 'spire', 'spile', 'smile']],
  ['palm', 'grip', 6, ['palm', 'pall', 'pail', 'pain', 'gain', 'grin', 'grip']],
  ['sloth', 'sleep', 7, ['sloth', 'slots', 'shots', 'shoes', 'shoer', 'sheer', 'sheep', 'sleep']],
  ['tooth', 'teeth', 8, ['tooth', 'booth', 'boots', 'boats', 'beats', 'bents', 'tents', 'tenth', 'teeth']],
  ['money', 'bribe', 10, ['money', 'boney', 'bones', 'banes', 'bases', 'basts', 'baits', 'brits', 'brins', 'brine', 'bribe']],
  // Monday
  ['bath', 'salt', 4, ['bath', 'bate', 'bale', 'sale', 'salt']],
  ['cross', 'roads', 5, ['cross', 'crass', 'grass', 'grads', 'goads', 'roads']],
  ['plow', 'soil', 6, ['plow', 'plop', 'poop', 'pool', 'cool', 'coil', 'soil']],
  ['sneer', 'scorn', 6, ['sneer', 'sheer', 'sheen', 'shewn', 'shown', 'shorn', 'scorn']],
  ['block', 'party', 7, ['block', 'blocs', 'blots', 'boots', 'borts', 'ports', 'parts', 'party']],
  ['lucky', 'seven', 8, ['lucky', 'ducky', 'dicky', 'dicey', 'dicer', 'diver', 'siver', 'sever', 'seven']],
  ['stock', 'piles', 9, ['stock', 'stack', 'stark', 'stars', 'sears', 'seals', 'sells', 'sills', 'pills', 'piles']],
  // Monday
  ['sharp', 'shoot', 4, ['sharp', 'share', 'shore', 'short', 'shoot']],
  ['gold', 'jade', 5, ['gold', 'bold', 'bald', 'bale', 'bade', 'jade']],
  ['swig', 'chug', 5, ['swig', 'twig', 'trig', 'trug', 'thug', 'chug']],
  ['lash', 'brow', 6, ['lash', 'bash', 'bass', 'boss', 'boos', 'bros', 'brow']],
  ['tiger', 'woods', 6, ['tiger', 'tiler', 'tiles', 'wiles', 'wilds', 'wolds', 'woods']],
  ['tinge', 'touch', 7, ['tinge', 'tings', 'tines', 'tires', 'tores', 'torcs', 'torch', 'touch']],
  ['quill', 'plume', 8, ['quill', 'quilt', 'guilt', 'guile', 'guide', 'glide', 'glime', 'glume', 'plume']],
  // Monday
  ['vows', 'oath', 4, ['vows', 'vaws', 'vats', 'oats', 'oath']],
  ['gold', 'ring', 5, ['gold', 'bold', 'bond', 'bind', 'rind', 'ring']],
  ['veil', 'lace', 6, ['veil', 'vail', 'mail', 'mall', 'male', 'mace', 'lace']],
  ['white', 'dress', 6, ['white', 'whits', 'writs', 'wries', 'dries', 'drees', 'dress']],
  ['bride', 'groom', 6, ['bride', 'brine', 'brins', 'brios', 'broos', 'broom', 'groom']],
  ['after', 'party', 7, ['after', 'ofter', 'oater', 'pater', 'parer', 'pares', 'parts', 'party']],
  ['toast', 'cheer', 9, ['toast', 'boast', 'blast', 'blest', 'bleat', 'cleat', 'cheat', 'cheap', 'cheep', 'cheer']],
  // Monday
  ['rare', 'find', 4, ['rare', 'fare', 'fire', 'fine', 'find']],
  ['mini', 'moon', 5, ['mini', 'mind', 'mild', 'mold', 'mood', 'moon']],
  ['back', 'rubs', 5, ['back', 'bank', 'bans', 'buns', 'runs', 'rubs']],
  ['shard', 'glass', 6, ['shard', 'chard', 'chars', 'chads', 'clads', 'class', 'glass']],
  ['lace', 'knot', 7, ['lace', 'lack', 'lock', 'look', 'loot', 'soot', 'snot', 'knot']],
  ['curry', 'favor', 8, ['curry', 'carry', 'carrs', 'cares', 'carer', 'caver', 'saver', 'savor', 'favor']],
  ['fluke', 'lucky', 10, ['fluke', 'flake', 'flame', 'blame', 'blams', 'beams', 'beaks', 'becks', 'bucks', 'lucks', 'lucky']],
  // Monday
  ['carb', 'diet', 4, ['carb', 'cart', 'dart', 'dirt', 'diet']],
  ['reef', 'bank', 5, ['reef', 'beef', 'bees', 'bens', 'bans', 'bank']],
  ['name', 'logo', 5, ['name', 'dame', 'dome', 'doge', 'loge', 'logo']],
  ['cheer', 'shout', 6, ['cheer', 'sheer', 'shear', 'sheal', 'shoal', 'shoat', 'shout']],
  ['chant', 'spell', 7, ['chant', 'chapt', 'chape', 'shape', 'shale', 'shall', 'shell', 'spell']],
  ['party', 'clown', 8, ['party', 'parts', 'ports', 'borts', 'boots', 'blots', 'blows', 'blown', 'clown']],
  ['outer', 'space', 10, ['outer', 'muter', 'mutes', 'motes', 'motts', 'moats', 'meats', 'seats', 'spats', 'spate', 'space']],
  // Monday
  ['hand', 'made', 4, ['hand', 'band', 'bane', 'bade', 'made']],
  ['yank', 'pull', 5, ['yank', 'bank', 'balk', 'ball', 'bull', 'pull']],
  ['scrap', 'shred', 5, ['scrap', 'strap', 'straw', 'strew', 'shrew', 'shred']],
  ['lode', 'vein', 6, ['lode', 'lore', 'lorn', 'loin', 'lain', 'vain', 'vein']],
  ['grave', 'stone', 6, ['grave', 'grove', 'prove', 'prone', 'phone', 'shone', 'stone']],
  ['sweet', 'heart', 8, ['sweet', 'skeet', 'skees', 'skeps', 'seeps', 'seers', 'sears', 'hears', 'heart']],
  ['steno', 'graph', 10, ['steno', 'stent', 'stint', 'saint', 'sains', 'gains', 'grins', 'grips', 'gripe', 'grape', 'graph']],
  // Monday
  ['pack', 'herd', 4, ['pack', 'hack', 'hark', 'hard', 'herd']],
  ['shake', 'awake', 4, ['shake', 'share', 'sware', 'aware', 'awake']],
  ['mush', 'room', 5, ['mush', 'mosh', 'most', 'moot', 'root', 'room']],
  ['fairy', 'tales', 5, ['fairy', 'fairs', 'fails', 'falls', 'talls', 'tales']],
  ['brush', 'floss', 6, ['brush', 'brash', 'brass', 'grass', 'glass', 'gloss', 'floss']],
  ['party', 'favor', 7, ['party', 'parts', 'pares', 'parer', 'paver', 'saver', 'savor', 'favor']],
  ['brain', 'waves', 9, ['brain', 'brawn', 'braws', 'brads', 'beads', 'bends', 'bands', 'banes', 'wanes', 'waves']],
  // Monday
  ['scan', 'skim', 4, ['scan', 'scam', 'sham', 'shim', 'skim']],
  ['blank', 'space', 5, ['blank', 'slank', 'spank', 'spark', 'spare', 'space']],
  ['crab', 'meat', 5, ['crab', 'crag', 'brag', 'brat', 'beat', 'meat']],
  ['prong', 'spike', 6, ['prong', 'prone', 'phone', 'shone', 'shine', 'spine', 'spike']],
  ['sleek', 'shiny', 7, ['sleek', 'sleep', 'sheep', 'sheer', 'shier', 'shies', 'shins', 'shiny']],
  ['blood', 'drive', 9, ['blood', 'blond', 'bland', 'blank', 'brank', 'crank', 'crane', 'crave', 'drave', 'drive']],
  ['beach', 'towel', 10, ['beach', 'bench', 'tench', 'tenth', 'tents', 'tints', 'tines', 'tined', 'toned', 'towed', 'towel']],
  // Monday
  ['bore', 'yawn', 4, ['bore', 'bare', 'barn', 'yarn', 'yawn']],
  ['pick', 'nose', 5, ['pick', 'nick', 'nice', 'nine', 'none', 'nose']],
  ['stink', 'smell', 5, ['stink', 'stank', 'stalk', 'stall', 'small', 'smell']],
  ['grind', 'crush', 6, ['grind', 'grand', 'grans', 'grass', 'crass', 'crash', 'crush']],
  ['free', 'will', 6, ['free', 'fret', 'feet', 'feel', 'fell', 'fill', 'will']],
  ['crisp', 'sharp', 7, ['crisp', 'crimp', 'chimp', 'chirp', 'chirk', 'chark', 'shark', 'sharp']],
  ['petty', 'small', 8, ['petty', 'peaty', 'peats', 'seats', 'spats', 'spate', 'spale', 'spall', 'small']],
  // Monday
  ['gate', 'lock', 4, ['gate', 'late', 'lace', 'lack', 'lock']],
  ['peep', 'look', 5, ['peep', 'peek', 'peck', 'pock', 'lock', 'look']],
  ['ships', 'fleet', 5, ['ships', 'slips', 'flips', 'flies', 'flees', 'fleet']],
  ['hunt', 'trap', 6, ['hunt', 'hent', 'heat', 'feat', 'frat', 'frap', 'trap']],
  ['junk', 'spam', 7, ['junk', 'punk', 'punt', 'pent', 'sent', 'seat', 'seam', 'spam']],
  ['stage', 'coach', 7, ['stage', 'stags', 'slags', 'clags', 'class', 'clash', 'clach', 'coach']],
  ['haste', 'speed', 9, ['haste', 'baste', 'basts', 'bases', 'based', 'bayed', 'sayed', 'skyed', 'skeed', 'speed']],
  // Monday
  ['home', 'base', 4, ['home', 'come', 'came', 'case', 'base']],
  ['fast', 'ball', 4, ['fast', 'bast', 'bait', 'bail', 'ball']],
  ['scare', 'spook', 5, ['scare', 'score', 'store', 'stork', 'stook', 'spook']],
  ['cross', 'bones', 6, ['cross', 'crops', 'coops', 'comps', 'comes', 'cones', 'bones']],
  ['grand', 'stand', 6, ['grand', 'brand', 'bland', 'blank', 'slank', 'stank', 'stand']],
  ['whoa', 'geez', 7, ['whoa', 'whop', 'shop', 'shod', 'shed', 'seed', 'geed', 'geez']],
  ['sweet', 'tooth', 8, ['sweet', 'sheet', 'sheer', 'shoer', 'shoes', 'shots', 'soots', 'sooth', 'tooth']],
  // Monday
  ['year', 'book', 4, ['year', 'bear', 'boar', 'boor', 'book']],
  ['shape', 'shift', 4, ['shape', 'share', 'shire', 'shirt', 'shift']],
  ['brown', 'green', 5, ['brown', 'brows', 'brews', 'brees', 'grees', 'green']],
  ['pain', 'free', 6, ['pain', 'gain', 'grin', 'brin', 'brie', 'bree', 'free']],
  ['play', 'date', 7, ['play', 'plat', 'peat', 'peas', 'pets', 'pats', 'pate', 'date']],
  ['chase', 'catch', 7, ['chase', 'cease', 'lease', 'leash', 'leach', 'letch', 'latch', 'catch']],
  ['stint', 'phase', 8, ['stint', 'saint', 'faint', 'feint', 'feist', 'feast', 'fease', 'pease', 'phase']],
  // Monday
  ['next', 'heir', 4, ['next', 'neat', 'heat', 'hear', 'heir']],
  ['grasp', 'clasp', 4, ['grasp', 'grass', 'crass', 'class', 'clasp']],
  ['boat', 'trip', 5, ['boat', 'brat', 'bray', 'tray', 'trap', 'trip']],
  ['wired', 'hyper', 5, ['wired', 'tired', 'tyred', 'typed', 'hyped', 'hyper']],
  ['grove', 'trees', 6, ['grove', 'grope', 'gripe', 'grips', 'trips', 'tries', 'trees']],
  ['home', 'away', 7, ['home', 'heme', 'here', 'hero', 'aero', 'aery', 'awry', 'away']],
  ['score', 'tally', 8, ['score', 'scare', 'scars', 'sears', 'seals', 'sells', 'tells', 'talls', 'tally']],
  // Monday
  ['side', 'dish', 4, ['side', 'wide', 'wise', 'wish', 'dish']],
  ['cape', 'town', 5, ['cape', 'care', 'core', 'corn', 'torn', 'town']],
  ['tear', 'drop', 5, ['tear', 'team', 'tram', 'trap', 'trop', 'drop']],
  ['loans', 'shark', 6, ['loans', 'leans', 'lears', 'sears', 'spars', 'spark', 'shark']],
  ['fries', 'shake', 7, ['fries', 'flies', 'flits', 'flats', 'slats', 'slate', 'slake', 'shake']],
  ['blood', 'sweat', 8, ['blood', 'blond', 'blend', 'bleed', 'bleep', 'sleep', 'sleet', 'sweet', 'sweat']],
  ['heart', 'break', 9, ['heart', 'hears', 'bears', 'beads', 'brads', 'braes', 'brees', 'breed', 'bread', 'break']],
  // Monday
  ['life', 'vest', 4, ['life', 'lift', 'left', 'lest', 'vest']],
  ['door', 'dash', 5, ['door', 'poor', 'pooh', 'posh', 'pash', 'dash']],
  ['mail', 'room', 5, ['mail', 'moil', 'mool', 'mood', 'rood', 'room']],
  ['crime', 'scene', 6, ['crime', 'chime', 'chine', 'shine', 'shone', 'scone', 'scene']],
  ['shake', 'blend', 7, ['shake', 'share', 'shark', 'shank', 'slank', 'blank', 'bland', 'blend']],
  ['sneer', 'taunt', 8, ['sneer', 'sheer', 'sheet', 'shent', 'stent', 'stint', 'saint', 'taint', 'taunt']],
  ['grind', 'teeth', 9, ['grind', 'grand', 'brand', 'brans', 'beans', 'beats', 'bents', 'tents', 'tenth', 'teeth']],
  // Monday
  ['class', 'grade', 4, ['class', 'crass', 'grass', 'grads', 'grade']],
  ['pure', 'good', 5, ['pure', 'pore', 'fore', 'ford', 'food', 'good']],
  ['lean', 'tilt', 5, ['lean', 'leal', 'teal', 'tell', 'till', 'tilt']],
  ['brass', 'tacks', 6, ['brass', 'brads', 'beads', 'beaks', 'becks', 'backs', 'tacks']],
  ['brave', 'heart', 7, ['brave', 'brake', 'braky', 'beaky', 'beaks', 'bears', 'hears', 'heart']],
  ['three', 'dozen', 8, ['three', 'threw', 'shrew', 'shred', 'sored', 'soled', 'doled', 'dozed', 'dozen']],
  ['quick', 'speed', 9, ['quick', 'quirk', 'quire', 'quite', 'quits', 'suits', 'spits', 'spies', 'spied', 'speed']],
  // Monday
  ['gait', 'walk', 4, ['gait', 'wait', 'wail', 'wall', 'walk']],
  ['taker', 'giver', 4, ['taker', 'laker', 'laver', 'liver', 'giver']],
  ['tour', 'show', 5, ['tour', 'tout', 'toot', 'soot', 'shot', 'show']],
  ['passe', 'dated', 6, ['passe', 'paste', 'pasts', 'pases', 'pates', 'dates', 'dated']],
  ['cross', 'walks', 7, ['cross', 'crops', 'coops', 'cooks', 'conks', 'wonks', 'wanks', 'walks']],
  ['sharp', 'point', 8, ['sharp', 'shark', 'shank', 'stank', 'stink', 'stint', 'saint', 'paint', 'point']],
  ['yeast', 'froth', 9, ['yeast', 'beast', 'beaut', 'beaus', 'beats', 'boats', 'boots', 'booth', 'broth', 'froth']],
  // Monday
  ['past', 'done', 4, ['past', 'pant', 'pane', 'pone', 'done']],
  ['trade', 'goods', 4, ['trade', 'grade', 'grads', 'goads', 'goods']],
  ['shake', 'shook', 5, ['shake', 'share', 'shark', 'shack', 'shock', 'shook']],
  ['coat', 'film', 6, ['coat', 'boat', 'bolt', 'bole', 'bile', 'file', 'film']],
  ['study', 'learn', 7, ['study', 'studs', 'scuds', 'scads', 'scars', 'sears', 'lears', 'learn']],
  ['stock', 'worth', 8, ['stock', 'shock', 'shook', 'shoos', 'shots', 'soots', 'sorts', 'worts', 'worth']],
  ['guilt', 'shame', 9, ['guilt', 'guile', 'guide', 'glide', 'glade', 'clade', 'clave', 'slave', 'shave', 'shame']],
  // Monday
  ['mull', 'wine', 4, ['mull', 'mill', 'mile', 'mine', 'wine']],
  ['holy', 'nite', 5, ['holy', 'hole', 'hope', 'nope', 'note', 'nite']],
  ['happy', 'jolly', 5, ['happy', 'haply', 'hiply', 'hilly', 'holly', 'jolly']],
  ['snow', 'cone', 6, ['snow', 'snot', 'soot', 'sort', 'sore', 'core', 'cone']],
  ['gravy', 'train', 6, ['gravy', 'grave', 'grace', 'trace', 'tract', 'trait', 'train']],
  ['scarf', 'glove', 7, ['scarf', 'scare', 'share', 'shave', 'slave', 'clave', 'clove', 'glove']],
  ['sweet', 'cider', 8, ['sweet', 'sleet', 'fleet', 'fleer', 'flyer', 'foyer', 'coyer', 'coder', 'cider']],
  // Monday
  ['fare', 'well', 4, ['fare', 'ware', 'wale', 'wall', 'well']],
  ['glass', 'clink', 4, ['glass', 'class', 'clans', 'clank', 'clink']],
  ['goals', 'start', 6, ['goals', 'goads', 'roads', 'roars', 'soars', 'stars', 'start']],
  ['next', 'stop', 5, ['next', 'neat', 'seat', 'stat', 'stot', 'stop']],
  ['great', 'grand', 6, ['great', 'greet', 'grees', 'greys', 'grays', 'grans', 'grand']],
  ['shade', 'blend', 7, ['shade', 'share', 'shark', 'shank', 'slank', 'blank', 'bland', 'blend']],
  ['paper', 'plane', 8, ['paper', 'pacer', 'paces', 'packs', 'pecks', 'peaks', 'peans', 'plans', 'plane']],
  // Monday
  ['cast', 'hook', 4, ['cast', 'cost', 'host', 'hoot', 'hook']],
  ['dawn', 'year', 5, ['dawn', 'daws', 'dews', 'yews', 'yeas', 'year']],
  ['cross', 'words', 5, ['cross', 'cress', 'creds', 'coeds', 'cords', 'words']],
  ['glint', 'shine', 6, ['glint', 'flint', 'fling', 'sling', 'swing', 'swine', 'shine']],
  ['style', 'brand', 7, ['style', 'stale', 'stalk', 'stank', 'slank', 'blank', 'bland', 'brand']],
  ['cloak', 'guise', 8, ['cloak', 'clock', 'click', 'slick', 'slice', 'slide', 'glide', 'guide', 'guise']],
  ['trunk', 'snout', 9, ['trunk', 'thunk', 'thank', 'shank', 'shark', 'share', 'shore', 'short', 'shout', 'snout']],
  // Monday
  ['work', 'desk', 4, ['work', 'dork', 'dock', 'deck', 'desk']],
  ['stand', 'still', 4, ['stand', 'stank', 'stalk', 'stall', 'still']],
  ['short', 'blurb', 6, ['short', 'sport', 'spurt', 'spurs', 'slurs', 'blurs', 'blurb']],
  ['grim', 'dark', 6, ['grim', 'grit', 'gait', 'wait', 'wart', 'dart', 'dark']],
  ['texts', 'codex', 7, ['texts', 'tents', 'cents', 'celts', 'colts', 'coles', 'codes', 'codex']],
  ['scary', 'clown', 8, ['scary', 'scars', 'scabs', 'slabs', 'blabs', 'blaws', 'blawn', 'blown', 'clown']],
  ['thick', 'bulky', 9, ['thick', 'think', 'thins', 'tains', 'tails', 'bails', 'balls', 'balks', 'balky', 'bulky']],
  // Monday
  ['rosy', 'pink', 4, ['rosy', 'posy', 'pony', 'piny', 'pink']],
  ['stare', 'blink', 5, ['stare', 'stane', 'stank', 'slank', 'blank', 'blink']],
  ['slant', 'slope', 6, ['slant', 'scant', 'scent', 'scene', 'scone', 'scope', 'slope']],
  ['lone', 'star', 6, ['lone', 'sone', 'sons', 'sous', 'sour', 'soar', 'star']],
  ['paper', 'white', 7, ['paper', 'parer', 'pares', 'parts', 'warts', 'waits', 'whits', 'white']],
  ['saint', 'grace', 7, ['saint', 'sains', 'gains', 'grins', 'grans', 'grads', 'grade', 'grace']],
  ['bidet', 'spray', 9, ['bidet', 'bided', 'sided', 'sired', 'shred', 'shrew', 'strew', 'straw', 'stray', 'spray']],
];

const zeroIndexDaysSinceEpoch = 19040; // 02/17/2022

function getCurrentGameIndex() {
  return getNumDaysSinceEpoch() % zeroIndexDaysSinceEpoch;
}

// Public-facing game number, starting with game #1
export function getCurrentGameNumber() {
  return getCurrentGameIndex() + 1;
}

export function isGameNumberCurrentGame(gameNumber) {
  const currentGameNumber = getCurrentGameNumber();
  return gameNumber === currentGameNumber;
}

// TODO: deprecate
export function getEpochDayFromGameNumber(gameNumber) {
  return zeroIndexDaysSinceEpoch + gameNumber - 1;
}

export function getWordPair(gameNumber) {
  const index = gameNumber - 1;
  return wordPairs[index];
}

export function getOptimalPathLength(gameNumber) {
  const wordPair = getWordPair(gameNumber);
  const [
    _startWord,
    _endWord,
    optimalPathLength,
  ] = wordPair;
  return optimalPathLength;
}

export function getYesterdayOptimalPath() {
  const index = getCurrentGameIndex() - 1;
  const [
    _startWord,
    _endWord,
    _optimalPathLength,
    optimalPath,
  ] = wordPairs[index];
  return optimalPath;
}

export function getSpecialEmoji(gameNumber) {
  const wordPair = getWordPair(gameNumber);
  const [
    _startWord,
    _endWord,
    _optimalPathLength,
    _optimalPath,
    specialEmoji,
  ] = wordPair;
  return specialEmoji;
}

export function getSpecialMessageData(gameNumber) {
  const wordPair = getWordPair(gameNumber);
  const [
    _startWord,
    _endWord,
    _optimalPathLength,
    _optimalPath,
    _specialEmoji,
    specialMessage,
    specialMessageBackgroundColor,
  ] = wordPair;
  return [specialMessage, specialMessageBackgroundColor];
}
